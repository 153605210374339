import gear from './images/gear.svg';

import AnsiB92_1Values from './values-ansi-b92_1';
import ISO4156Values from './values-iso-4156';
import React from 'react';
import J500Values from './values-j500';
import CustomValues from './values-custom';

import Drawing from './drawing';
import InchMMCalculator from "./inch-mm"
import * as util from './utilities';

import InvoluteScriptGenerator from "./script-involute"
import DxfShapeRenderer from "./shape-dxf"
import ScrShapeRenderer from "./shape-scr"
import {SCALE_FACTOR} from "./shape-preview"
import {SVGShapeRenderer} from "./shape-svg"

import './css/app.css'; 
import './css/slider.css';

// import * as firebase from "firebase";
// import * as firebaseui from "firebaseui";

export const SPECIFICATIONS = {
  ANSI_1996	: "ANSI B92.1-1996 (Involute)",
  ISO_4156  : "ANSI B92.2M / ISO 4156 (Involute)"
//  CUSTOM    : "Custom",
//  DIN_5480  : "DIN 5480 (Involute)",
//  DIN_5482  : "DIN 5482 (Involute)",
//  DIN_5481  : "DIN 5481 (Serration)",
//  INVOLUTE  : "Non Standard Involute",
//  SERRATION : "Non Standard Serration",
//  J500		  : "SAE J500 (Serration)",
//  ISO_41    : "DIN / ISO 14"
  };

  export const FILE_TYPES = {
    SCRIPT	: "Autocad srcipt file (.SCR)",
    DXF     : "Autocad DXF file (.DXF)",
    SVG     : "Scaleable Vector Graphis (.SVG)"
  };

  export default class App extends  React.Component {
  
  constructor (props)
  {
    super(props);

    this.splineValues = {};
    this.scriptFile = null;

    this.state = {
			specification:  SPECIFICATIONS.ANSI_1996,
      splineValues:   this.splineValues
    }

    // This binding is necessary to make `this` work in the callbacks
    this.handleSpecChange = this.handleSpecChange.bind(this);
    this.registerScriptGenerator = this.registerScriptGenerator.bind(this);
    this.handleCalcChanged = this.handleCalcChanged.bind(this);
    this.createAutoCADDrawing = this.createAutoCADDrawing.bind(this);

    // Default to the ANSI B92 screen and drawing. This just looks better than coming to a blank screen.
    this.state.inputValues = <AnsiB92_1Values registerScriptGenerator={this.registerScriptGenerator} onChange={this.handleCalcChanged} />;

  }

  componetWillMount() {
/*
//    apiKey: “xxxxxxxxxxxxxxxxxxxxxxxx”,

    // Initialize Firebase
    var config = {
      authDomain: 'spline-generator.firebaseapp.com',
      projectId: 'spline-generator'
    };

    var firebaseApp = firebase.initializeApp(config);
    console.log("FIREBASE NAME: " + firebaseApp.name);  // "[DEFAULT]"
/*
    // Initialize the FirebaseUI Widget using Firebase.
    var ui = new firebaseui.auth.AuthUI(firebase.auth());    

    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', uiConfig);
*/

  }

  componentDidMount(){
    document.title = "Spline Generator";
  }
/*
  componentDidMount() {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            console.log('User is signed in.');
        } else {
            // No user is signed in.
            console.log('No user is signed in.');
        }
    });
}
*/

  handleSpecChange(event)
  {
		let updateState = {};
		let id =  event.target.id;
		let newValue = event.target.value;
		updateState[id] = newValue;

    if (id === "specification")
    {
      let inputValues;
      switch (newValue)
      {
        case SPECIFICATIONS.CUSTOM: 
          inputValues = <CustomValues registerScriptGenerator={this.registerScriptGenerator} onChange={this.handleCalcChanged} />;
          break;      
        case SPECIFICATIONS.J500:
          inputValues = <J500Values registerScriptGenerator={this.registerScriptGenerator} onChange={this.handleCalcChanged} />;
          break;
        case SPECIFICATIONS.ISO_4156:
          inputValues = <ISO4156Values registerScriptGenerator={this.registerScriptGenerator} onChange={this.handleCalcChanged} />;
          break;
        case SPECIFICATIONS.ANSI_1996:
        default:
          inputValues = <AnsiB92_1Values registerScriptGenerator={this.registerScriptGenerator} onChange={this.handleCalcChanged} />;
          break;
      }

      updateState.inputValues = inputValues;  
    }

    this.setState(updateState);
    
  } // handleSpecChange


  handleCalcChanged(splineValues)
  {
    this.setState({
      splineValues: splineValues
    });
    
  } // handleCalcChanged

  
  // Each individual engine is responsible for not only collecting the values it needs
  // but also for generating it's own script for drawing. As such, when the engine is
  // created, it registeres itself with the app so that the app can know how to generate
  // the drawing script when needed. 
  registerScriptGenerator(scriptGenerator)
  {
    this.setState({
      scriptGenerator: scriptGenerator
    });
  }


  createAutoCADDrawing(fileType, e)
  {  
    let renderer;

    switch (fileType)
    {
      case FILE_TYPES.DXF:
        this.fileName = "autocad.dxf";
        renderer = new DxfShapeRenderer();
        break;
      case FILE_TYPES.SCR:
        this.fileName = "autocad.scr";
        renderer = new ScrShapeRenderer();
        break;
      case FILE_TYPES.SVG:
        this.fileName = "autocad.svg";

        // Default to using the max major as the size of the drawing. However, if we have an external
        // drawing, the pins are on the outside. So, use the pin measurement if it is more than the
        // max major.
        let width = this.state.splineValues.maxMajor;
        let pinMeasurement = this.state.splineValues.pinCenterRadius ?  this.state.splineValues.pinCenterRadius * 2 + this.state.splineValues.pinDiameter : width;
        if (width < pinMeasurement) {
          width = pinMeasurement;
        }

        // Since the splines are so small, we multiply all the valyes by a SCALE_FACTOR. That way the drawing is
        // higher resolution and looks better when rendered in SVG.
        width *= SCALE_FACTOR;
        var boxStr = -width/2 + " " + -width/2 + " " + width + " " + width;

        renderer = new SVGShapeRenderer(boxStr);
        break;
    }
    
    var autoCADScript = new InvoluteScriptGenerator(this.state.splineValues, renderer);
    var script = autoCADScript.createScript();

		// Write the script array to a string.
		var scriptText = "";
		for (var i = 0, len = script.length; i < len; i++)
		{
  			scriptText += script[i] + "\n";
		}

    var data = new Blob([scriptText], {type: 'text/plain'});

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (this.scriptFile !== null)
    {
      window.URL.revokeObjectURL(this.scriptFile);
    }

    this.scriptFile = window.URL.createObjectURL(data);
    this.setState({
      scriptFile: this.scriptFile
    });

  } // createAutoCADDrawing



  render()
  {
    return (
      <div className="App">

        <header className="App-header">
          <div><img src={gear} className="App-logo" alt="logo" /></div>
          <div><h1>Spline Generator III</h1></div>
          <div className="InchToMM"><InchMMCalculator /></div>
        </header>
        
        <div id="firebaseui-auth-container">Authorization...</div>

        <div className="App-data">

          <div className="App-values">
          
            <div className="Specification">
              <label htmlFor="specification">Specification</label>
              <select id="specification" value={this.state.specification} onChange={this.handleSpecChange}>
                {util.createSelectItems(SPECIFICATIONS)}
              </select>
            </div>

            <div className="App-input">
              {this.state.inputValues}
            </div>

            <div>
              <h4>Download:</h4>
            </div>

            <div className="Download">
              <a className="button" download={this.fileName} href={this.scriptFile} style={{display: 'block'}} >
                <button onClick={(e) => this.createAutoCADDrawing(FILE_TYPES.DXF, e)} >AutoCAD DXF</button>
              </a>
              <a className="button" download={this.fileName} href={this.scriptFile} style={{display: 'block'}} >
                <button onClick={(e) => this.createAutoCADDrawing(FILE_TYPES.SCR, e)} >AutoCAD SCR</button>
              </a>
              <a className="button" download={this.fileName} href={this.scriptFile} style={{display: 'block'}} >
                <button onClick={(e) => this.createAutoCADDrawing(FILE_TYPES.SVG, e)} >SVG</button>
              </a>
            </div>

          </div>

          <div className="App-drawing">
            <Drawing scriptGenerator={this.state.scriptGenerator} splineValues={this.state.splineValues}/>
          </div>

        </div>
      </div>
    );
  }
} // render

/*
var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('firebaseui-auth-container').style.display = 'none';
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '<url-to-redirect-to-on-success>',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>'
};

*/
