/*
	The default basic rendering just does nothing. These are 
	meant to be overridden if the basic shape is supported by
	the target drawing type.
*/
export default class BaseShapeRenderer
{
	curve() {}
	line () {}
	circle () {}
	arc () {}
	text () {}
	layer () {}
	header () {}
	footer () {}
	COLOR () { return {}; }
	setStrokeWidth(strokeWidth) {}

}  // BaseShapeRender