import React from 'react';
import swal from 'sweetalert';
import ValuesBase from './values-base';
import InvoluteScriptGenerator from "./script-involute"

import OverridableInput from './overridable-input';
import * as util from './utilities';
import * as ansi from './calc-ansi-b92_1';
import './css/values.css';


export default class AnsiB92_1Values extends ValuesBase
{
	constructor(props)
	{		
		super(props);

		// Setup default values for the first time the values are loaded.
		this.state = {
			numTeeth: 20,
			diametralPitch: 2.5,
			pressureAngle: 30,
			splineType: ansi.SPLINE_TYPE.EXTERNAL,
			fitType: ansi.FIT_TYPE.FLAT,
			classFit: ansi.CLASS_FIT.CLASS_5,
			isOverridden: false
		};

		// Calculate the initial values.
		this.state.splineValues = this.calculateValues();
		Object.assign(this.state, this.state.splineValues);			
		
		this.state.major = (this.state.splineValues.maxMajor + this.state.splineValues.minMajor) / 2;
		this.state.minor = (this.state.splineValues.maxMinor + this.state.splineValues.minMinor) / 2;
		this.state.width = this.state.splineValues.width;

		let pinMeasures = this.calcPinMeasures(this.state.splineValues.pinDiameter);
		Object.assign(this.state, pinMeasures);
		
	} // constructor


	updateCalculations()
	{
		let splineValues = this.calculateValues();
		let newState = Object.assign(this.state, splineValues);
		newState.major = (splineValues.maxMajor + splineValues.minMajor) / 2;
		newState.minor = (splineValues.maxMinor + splineValues.minMinor) / 2;
		newState.width = (splineValues.maxActual + splineValues.minActual) / 2;

		let pinMeasures = this.calcPinMeasures(splineValues.pinDiameter);
		newState = Object.assign(newState, pinMeasures);

		this.setState(newState, this.notifyUpdate);
       
    } // updateCalculations
    

	// Check to see if the major diameter is so large that the involute points will intersect before reaching the major.
	checkMajor(major, baseDiameter, pitchDiameter, width)
	{    
    	// Calculate the angle of the involute point where it intersects the pitch circle.
		// Half of this value is the center where two involutes will intersect when draw both halves.
		var pt = util.involuteCircleIntersect(baseDiameter, pitchDiameter);
		var circularSpaceAngle = util.toDegrees(width / (pitchDiameter / 2));
		var midpointAngle = circularSpaceAngle / 2 + pt.t;

		// When the t value of the major diameter intersection is greater than the midpoint angle, then 
		// the major diameter is too large and the involute will cross each other.
		var involuteIntersectPtMajor = util.involuteCircleIntersect(baseDiameter, major);
		if (involuteIntersectPtMajor.t > midpointAngle)
		{
			// ERROR: The major diameter is larger than tne intersection of the two involutes for each side of the teeth.
			return false;
		}
		return true;

	} // checkMajor


	// We don't support drawing an involute smaller than the base circle since there is no involute form at that point.
	checkMinor(minor, baseDiameter, pitchDiameter, width)
	{    
		// Calculate the point that the involute curve is being drawn at starting at the minor.

		// Calculate the angle of the involute point where it intersects the pitch circle.
		// Half of this value is the center where two involutes will intersect when draw both halves.
		var pt = util.involuteCircleIntersect(baseDiameter, pitchDiameter);
		var circularSpaceAngle = util.toDegrees(width / (pitchDiameter / 2));
		var midpointAngle = circularSpaceAngle / 2 + pt.t;

		var widthAngle = util.toRadians(midpointAngle);

		var involuteIntersectPtMinor = util.involuteCircleIntersect(baseDiameter, minor);		

		let angle = involuteIntersectPtMinor.angle;

		var angleRadians = util.toRadians(angle);
		var x = ((baseDiameter) / 2 * (Math.cos(angleRadians) + angleRadians * Math.sin(angleRadians)));
		var y = ((baseDiameter / 2) * (Math.sin(angleRadians) - angleRadians * Math.cos(angleRadians))) 
		
		y *= -1; // flip the y based on what side we are creating the profile for.

		// Now rotate the invoute profile point by the incoming tooth angle.
		var xNew = x * Math.cos(widthAngle) - y * Math.sin(widthAngle);
		var yNew = x * Math.sin(widthAngle) + y * Math.cos(widthAngle);

		// xNew and yNew are now the point that we would start to draw the involute curve from the minor.
		// So we calculate this angle. If it is greater than the max angle size, then the teeth would collide
		// at the minor.
		var toothAngle = util.toDegrees(Math.atan2(yNew, xNew));
		var maxAngle = 360 / this.state.numTeeth;

		if ((toothAngle * 2) > maxAngle)	
		{
			// ERROR: The the width is too large for the minor diameter.
			return false;
		}
		return true;

	} // checkMinor


	// calculateValues is called by the base class when it needs new spline values from the spec.
	// The base class will also update the state based on any new spline values
	// that have changed and notify the parent of the changes. All we have to do here is
	// call the appropriate engine to do the calulation.
	calculateValues()
	{		
		// Calculate the spline values based on the input
		let splineValues = new ansi.SplineValues (
		this.state.numTeeth, 
		this.state.diametralPitch,
		this.state.pressureAngle,
		this.state.splineType,
		this.state.fitType,
		this.state.classFit );

		return splineValues;
		
	} // calculateValues


	isOverridden(state)
	{
		return (state.majorOverridden ||
			state.minorOverridden ||
			state.widthOverridden ||
			state.pinOverridden);
	}

	
	resetOverridden()
	{
		return {
			majorOverridden: false,
			minorOverridden: false,
			widthOverridden: false,
			pinOverridden: false
		};
	}


	processValueUpdate(id, newValue)
	{
		var oldValue = this.state[id];
		var isValid = true;
		
		// If the new value is the same as the old value, then there is no work to do.
		if (String(newValue) === String(oldValue)) return;

		let updateState = {};
		let askingUser = false;

		switch (id)
		{
			case "width":

				isValid = this.checkMinor(this.state.minor, this.state.baseDiameter, this.state.pitchDiameter, newValue);
				if (!isValid) {
					askingUser = true;
					swal({
						title: "Width to large",
						text: "The involute sides of each tooth will intersect at the minor. Please decrease the width or increase the minor diameter.",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);	
					break;			
				}
				
				// See if the new width will intersect the teeth before the major diameter. If it does, then the
				// sides of each tooth will cross and reverse on each other. So, we have to let the user know
				// that the tooth won't reach the major they want given the new tooth width.

				isValid = this.checkMajor(this.state.major, this.state.baseDiameter, this.state.pitchDiameter, newValue);
				if (!isValid) {
					askingUser = true;
					swal({
						title: "Width to small",
						text: "The involute sides of each tooth will intersect before reaching the major diameter. Please increase the width or decrease the major diameter.",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);
					break;				
				}
				else
				{
					updateState.widthOverridden = true;
					this.state.width = newValue;
					let pinMeasures = this.calcPinMeasures(this.state.pinDiameter);
					updateState = Object.assign(updateState, pinMeasures);	
				}
				break;

			case "pinDiameter":
				updateState.pinOverridden = true;
				newValue = Number(newValue);
				this.state.pinDiameter = newValue;
				let pinMeasures = this.calcPinMeasures(this.state.pinDiameter);
				updateState = Object.assign(updateState, pinMeasures);
				break;


			case "major":
				if (newValue <= this.state.minor)
				{
					askingUser = true;
					swal({
						title: "Major diamter to small",
						text: "The major diameter cannot be smaller than the minor diameter",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);				
				}

				isValid = this.checkMajor(newValue, this.state.baseDiameter, this.state.pitchDiameter, this.state.width);
				if (!isValid) {
					askingUser = true;
					swal({
						title: "Major diamter to large",
						text: "The involute sides of each tooth will intersect before reaching the major diameter. Please reduce the major diameter size.",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);				
				}
				updateState.majorOverridden = true;
				break;

			case "minor":
			if (newValue >= this.state.major)
			{
				askingUser = true;
				swal({
					title: "Minor diameter to large",
					text: "The minor diameter cannot be larger than the major diameter",
					icon: "info"
				  })
				.then(() => {
						updateState[id] = oldValue;
						this.setState(updateState);
					}
				);				
			}

			isValid = this.checkMinor(newValue, this.state.baseDiameter, this.state.pitchDiameter, this.state.width);
				if (!isValid)
				{
					swal({
						title: "Minor diameter to small",
						text: "Minor diameters smaller than the base diameter are not supported. Please increase the size of the minor diameter.",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);				
				}
				else
				{
					updateState.minorOverridden = true;
				}
				break;

			case "numTeeth":
			case "diametralPitch":
			case "pressureAngle":
			case "splineType":
			case "fitType":
			case "classFit":
				if (this.isOverridden(this.state)) {
					// Check to see if the user wants to continue
					askingUser = true;
					swal({
						title: "Are you sure?",
						text: "Some values have been overridden. Changing this will reset the calculations and remove the overridden changes. Are you sure you want to continue?", 
						icon: "warning",
						buttons: ["Cancel", "Continue"],
						dangerMode: true,
					  })
					  .then(shouldContinue => {
							if (shouldContinue) {
								// Swtiching back to calculated values
								updateState[id] = newValue;
								Object.assign(updateState, this.resetOverridden());
								this.setState(updateState, this.updateCalculations);
							}
							else {
								// Cancel selected
								updateState[id] = oldValue;
								this.setState(updateState);
							}
					  	}
					);				
				}
				break;
		}

		if (!askingUser) {
			// Check to see if changing this value will make is so we can't calculate
			this.calculateValues();
			
			updateState[id] = newValue;
			let isOverridden = this.isOverridden(updateState);
			this.setState(updateState, isOverridden ? this.notifyUpdate : this.updateCalculations);
		}
/*
		catch(e)
		{
			window.alert(e.toString());

			// Reset the value and display an error.
			this.state[id] = oldValue;		
			let updateState = [];
			updateState[id] = oldValue;
			this.setState(updateState);
			return false;
        }		
*/	
	} // processValueUpdate


	calcPinMeasures(pinDiameter)
	{
		// Calculate the pin measurements at the user specified width.
		let measurements = ansi.involutePinMeasurements(
			this.state.numTeeth,
			this.state.pitchDiameter,
			this.state.baseDiameter,
			this.state.pressureAngle,
			this.state.splineType,
			this.state.width,
			pinDiameter);

		return {
			pinDiameter: 		Number(pinDiameter),
			pinMeasurement: 	Number(measurements.pinMeasurement),
			pinCenterRadius: 	Number(measurements.pinCenterRadius)
		};

	} // calcPinMeasures


	// createScript knows which script generator to use based on the type of values and 
	// calculation engine that this object represents. So, when we need a scipt generated
	// we just as the object to create me a script generator of the appropriate type and 
	// generate the script.
	createScript(renderer)
	{
		let splineValues = Object.assign(this.state.splineValues, this.state);			
		let scriptGeneator = new InvoluteScriptGenerator(splineValues, renderer);	
		return scriptGeneator.createScript();

	} // createScript
	
	
	render()
	{
	    return (
				<div>					
					<div>
						<h4>Specification Settings:</h4>
					</div>					
					
					<div className="input-values">

						<label htmlFor="numTeeth">Teeth</label>
						<OverridableInput precision="0" id="numTeeth" value={this.state.numTeeth} onBlur={this.handleBlur} />

						<label htmlFor="diametralPitch">Diametral Pitch</label>
						<OverridableInput precision="1" id="diametralPitch"  value={this.state.diametralPitch}  onBlur={this.handleBlur} />

						<label htmlFor="pressureAngle">Pressure Angle</label>
						<select id="pressureAngle" value={this.state.pressureAngle} onChange={this.handleChange} >
							{util.createSelectItems(ansi.PRESSURE_ANGLE)}
						</select>
						
						<label htmlFor="splineType">Type</label>
						<select id="splineType" value={this.state.splineType} onChange={this.handleChange} >
							{util.createSelectItems(ansi.SPLINE_TYPE)}
						</select>

						<label htmlFor="fitType">Fit</label>
						<select id="fitType" value={this.state.fitType} onChange={this.handleChange} >
							{util.createSelectItems(ansi.FIT_TYPE)}
						</select>

						<label htmlFor="classFit">Class</label>
						<select id="classFit" value={this.state.classFit} onChange={this.handleChange} >
							{util.createSelectItems(ansi.CLASS_FIT)}
						</select>
					
					</div>

					<div>
						<h4>Drawing Values:</h4>
					</div>

					<div className="input-values">
					
						<label htmlFor="major">Major</label>
						<OverridableInput overridden={this.state.majorOverridden} id="major" value={this.state.major} onBlur={this.handleBlur} />

						<label htmlFor="minor">Minor</label>
						<OverridableInput overridden={this.state.minorOverridden} id="minor" value={this.state.minor} onBlur={this.handleBlur} />

						<label htmlFor="width">Tooth Thickness</label>
						<OverridableInput overridden={this.state.widthOverridden} id="width" value={this.state.width} onBlur={this.handleBlur} />
						
					</div>

					<div>
						<h4>Pin Dimensions:</h4>
					</div>

					<div className="input-values">
							<label htmlFor="pinDiameter">Pin Diameter</label>
							<OverridableInput overridden={this.state.pinOverridden} id="pinDiameter" value={this.state.pinDiameter} onBlur={this.handleBlur}/>

							<label htmlFor="pinMeasurement">Measurement</label>
							<OverridableInput disabled id="pinMeasurement" value={this.state.pinMeasurement}/>

					</div>

					<div>
						<h4>Specification Values:</h4>
					</div>

					<div className="input-values">

						<label htmlFor="minMajor">Min Major</label>
						<OverridableInput disabled id="minMajor" value={this.state.minMajor} />

						<label htmlFor="maxMajor">Max Major</label>
						<OverridableInput disabled id="maxMajor" value={this.state.maxMajor} />

						<label htmlFor="minMinor">Min Minor</label>
						<OverridableInput disabled id="minMinor" value={this.state.minMinor} />

						<label htmlFor="maxMinor">Max Minor</label>
						<OverridableInput disabled id="maxMinor" value={this.state.maxMinor} />

						<label htmlFor="formDiameter">Form Diameter</label>
						<OverridableInput disabled id="formDiameter" value={this.state.formDiameter} />

						<label htmlFor="pitchDiameter">Pitch Diameter</label>
						<OverridableInput disabled id="pitchDiameter" value={this.state.pitchDiameter} />

						<label htmlFor="baseDiameter">Base Diameter</label>
						<OverridableInput disabled id="baseDiameter" value={this.state.baseDiameter} />

						<label htmlFor="toothHeight">Tooth Height</label>
						<OverridableInput disabled id="toothHeight" value={this.state.toothHeight} />

						<label htmlFor="basePitch">Base Pitch</label>
						<OverridableInput disabled id="basePitch" value={this.state.basePitch} />

						<label htmlFor="minEffective">Min Effective</label>
						<OverridableInput disabled id="minEffective" value={this.state.minEffective} />

						<label htmlFor="maxEffective">Max Effective</label>
						<OverridableInput disabled id="maxEffective" value={this.state.maxEffective} />

						<label htmlFor="minActual">Min Actual</label>
						<OverridableInput disabled id="minActual" value={this.state.minActual} />

						<label htmlFor = "maxActual">Max Actual</label>
						<OverridableInput disabled id="maxActual" value={this.state.maxActual} />

					</div>

				</div>
		  );
		  
	} // render

} // AnsiValues
