import {CalculationBase} from "./calc-base"
import * as util from './utilities';

export {CalculationException} from "./calc-base"

export const SPLINE_TYPE = { 
    INTERNAL	: "Internal",
    EXTERNAL 	: "External" 
  };
  
export const ROOT_TYPE = {
    FLAT		: "Flat Root",
    FILLET 	: "Fillet Root"
};

export const SPLINE_FIT = {
  FIT_H : "H/h",
  FIT_F	: "H/f",
  FIT_E	: "H/e",
  FIT_D	: "H/d"
};

export const CLASS_FIT = {
    //  CLASS_1	: "Class 1 - ASA",
    //  CLASS_2	: "Class 2 - ASA",
    CLASS_4 : "Class 4",
    CLASS_5	: "Class 5",
    CLASS_6	: "Class 6",
    CLASS_7	: "Class 7"
    //  CLASS_A	: "Class A - SAE",
    //  CLASS_B	: "Class B - SAE",
    //  CLASS_C	: "Class C - SAE"
};

export const PRESSURE_ANGLE = [
  30, 37.5, 45
];

const ISO_R40 = [10, 10.6, 11.2, 11.8, 12.5, 13.2, 14, 15, 16, 17, 18, 19, 20, 21.2, 22.4, 23.6, 25, 26.5, 28, 30, 31.5, 33.5, 35.5, 37.5, 40, 42.5, 45, 47.5, 50, 53, 56, 60, 63, 67, 71, 75, 80, 85, 90, 95, 100 ];


/*
    measurePins(numTeeth, type, actual, pinDiameter)

    numTeeth = number of teeth for this spline, specified by the user.
    pitchDiameter = Spline pitch diameter
    baseDiameter = Spline base diameter
    pressureAngle = Splline pressure angle
    splineType = Spline type as either internal or external
    width = Actual tooth thicknes or space width
    pinDiameter = Diameter of measuring pin we want to calculate the pin measurement for.

  */
 export function involutePinMeasurements(numTeeth, pitchDiameter, baseDiameter, pressureAngle, splineType, width, pinDiameter)
 {
   /*
     Note: When calculating the pin locations, we need the minor and major diameters as show in the spec.
     The spec always shows the minimum minor and the maximum major.
   */
   var M_ie = 0;
   var invTheta_ie = 0;
   if (SPLINE_TYPE.INTERNAL === splineType)
   {
     pinDiameter = -pinDiameter;
     invTheta_ie = width / pitchDiameter + ( Math.tan(util.toRadians(pressureAngle)) - util.toRadians(pressureAngle) ) + pinDiameter / baseDiameter;

   }
   else // EXTERNAL
   {
     invTheta_ie = width / pitchDiameter + ( Math.tan(util.toRadians(pressureAngle)) - util.toRadians(pressureAngle) ) + pinDiameter / baseDiameter - Math.PI / numTeeth;
   }

   // Calculate Theta_ie from invTheta_ie

   /* 
     We are going to calculate the tooth thickness at the point of contact. Because there is no direct method
     for determination of tooth thickness at any point along an involute curve, several approximations
     have been developed. See http://gearsolutions.com/features/calculating-the-inverse-of-an-involute/

     Here we use the Irving Laskin technique.

     For involute angles up to 30 degrees with two approximations, there is no error to six significant digits.
     With four approximations this is true to 64.87 degrees.
   */

   // Calculate the first approximation
   var f1 = 1.441 * Math.cbrt(invTheta_ie) - (0.374 * invTheta_ie);

   // Calculate the second approximation
   var f2 = f1 + (invTheta_ie - ( Math.tan( f1 ) - f1 ) ) / ( Math.pow(Math.tan(f1),2));

   // Calculate the thrid approximation
   var f3 = f2 + (invTheta_ie - ( Math.tan( f2 ) - f2 ) ) / ( Math.pow(Math.tan(f2),2));

   // Calculate the fourth approximation
   var Theta_ie = f3 + (invTheta_ie - ( Math.tan( f3 ) - f3 ) ) / ( Math.pow(Math.tan(f3),2));
   
/*
   This was the old calculation and is what is in the spec. However, we don't use this since we need to know where to draw the pins
   as well as the measure over them when they are not across from each other. So, we always calculate like there are an even number
   of pins and then we compensat for the pins not being directly across from each other if it is odd
*/
   if (util.isEven(numTeeth))
   {
       M_ie = baseDiameter * (1 / Math.cos(Theta_ie)) + pinDiameter;
   }
   else
   { 
       M_ie = (baseDiameter * Math.cos( util.toRadians( 90 / numTeeth ) ) ) * ( 1 / Math.cos(Theta_ie)) + pinDiameter;
   }

  // Default to an even number of teeth and set the radius to the center of the pin M_ie / 2. This is used to get the radius from the
  // center of the spline circle to the center of where the pin should be regardless if the pins are over even or odd teeth.
   var pinCenterRadius = baseDiameter * (1 / Math.cos(Theta_ie)) / 2; 
  
   // NOTE: Dci is the diameter of where the pin touches the involute. We currenlty don't use it and thus
   // only pass back the pin measure at this point. However, we calculate it here since it's such a pain
   // to figure out in the future if we ever do want to use it.

   // Calculate the conctact diameter of the pin from FIG 23 and FIG 24 of Section 21
/*
   var tanDci = Math.tan(Theta_ie) - pinDiameter / baseDiameter; // Note: the sign of the pin diameter is already changed via external or internal, above.
   var Theta_ci = Math.atan(tanDci);
   var Dci = baseDiameter * (1 / Math.cos(Theta_ci));
*/ 

   // Return both the measure over or under the pins so we know what the diamter is to draw the pins as well as the cord diameter across the pins for measurements.
   return {
      pinDiameter:      Number(pinDiameter),
      pinMeasurement: 	Number(M_ie),
      pinCenterRadius:  Number(pinCenterRadius)
    };
     
 } // involutePinMeasurements
 

export class SplineValues extends CalculationBase
{
  constructor(numTeeth, module, pressureAngle, splineType, rootType, classFit)
  {
    super("ISO 4156 / ANSI 92.2M");

    // Make sure any strings passed in are converted to numbers;
    numTeeth = Number(numTeeth);
    module = Number(module);
    pressureAngle = Number(pressureAngle);
    
    // Check to make sure that the parameters are inline with the specification.
    this.checkParams(numTeeth, module, pressureAngle, splineType, classFit);

    this.numTeeth = numTeeth;
    this.module = module;
    this.pressureAngle = pressureAngle;
    this.splineType = splineType;
    this.rootType = rootType;
    this.classFit = classFit;
    this.pitchDiameter = this.module * this.numTeeth;
    this.baseDiameter = this.module * this.numTeeth * Math.cos(util.toRadians(this.pressureAngle));
    this.basePitch = Math.PI * this.module * Math.cos(util.toRadians(this.pressureAngle));
    this.circularPitch = Math.PI * this.module;
    this.formClearance = 0.1 * this.module;
    
    this.calcTotalTolerance();
    this.calcFormDiameter();
    this.calcMinor();
    this.calcMajor();
    this.calcSpaceAndToothWidth();
    this.toothHeight = (this.maxMajor - this.maxMinor) / 2;

    this.calcPins();

  } // constructor


  /*
    checkParams will make sure that all of the values passed in match the ANSI standard and this
    spline follows the specification. If not, it throws an error with a message and the value.
  */  
  checkParams(numTeeth, module, pressureAngle, splineType, classFit)
  {
    if ((numTeeth < 6) || (numTeeth > 100))
      this.throwError(numTeeth, "Number of teeth canont be less than 6 or greater than 100");
    
    if ((module < 0.5) || (module > 10))
      this.throwError(module, "Module can not be less than 0.5 or greater than 10");
    
    switch (pressureAngle)
    {
      case 30:
      case 37.5:
      case 45:
        break;
      default:
        this.throwError(pressureAngle, "Pressure angle must be 30, 37.5 or 45 degrees");
    }

    switch(splineType)
    {
      case SPLINE_TYPE.EXTERNAL:
      case SPLINE_TYPE.INTERNAL:
        break;
      default:
        this.throwError(splineType, "Spline type must be " + SPLINE_TYPE.INTERNAL + " or " + SPLINE_TYPE.EXTERNAL);  
    }

    switch (classFit) 
    {
      case CLASS_FIT.CLASS_4:
      case CLASS_FIT.CLASS_5:    
      case CLASS_FIT.CLASS_6:
      case CLASS_FIT.CLASS_7:
        break;
      default: 
        this.throwError(classFit, "Class fit must be " + CLASS_FIT.CLASS_4 + ", " + CLASS_FIT.CLASS_5 + ", " + CLASS_FIT.CLASS_6 + " or " + CLASS_FIT.CLASS_7);
      break;
    }

  } // checkParams

  
  /*
  
    Fp = Total index variation
    Paragraph: 8.3

    Note: Value is in micrometer
    
  */
  get Fp()
  {
    let value = 0;
    let L = this.module * this.numTeeth * (Math.PI / 2);
    switch (this.classFit) 
    {
      case CLASS_FIT.CLASS_4:
        value = 2.5 * Math.sqrt(L) + 6.3;
        break;
      case CLASS_FIT.CLASS_5:    
        value = 3.55 * Math.sqrt(L) + 9;
        break;
      case CLASS_FIT.CLASS_6:
        value = 5.0 * Math.sqrt(L) + 12.5;
        break;
      case CLASS_FIT.CLASS_7:
        value =  7.1 * Math.sqrt(L) + 18;
        break;
    }

    // Convert to mm since it is in micrometers.
    return value * 0.001;
    
  } // Fp


  /*
  
    Ff = Total profile variation
    Paragraph: 8.4

    Note: Value is in micrometer

  */
  get Ff() 
  {
    let value = 0;
    let f = this.module + 0.0125 * this.module * this.numTeeth;
    switch (this.classFit) 
    {
      case CLASS_FIT.CLASS_4:
        value = 1.6 * f + 10;
        break;
      case CLASS_FIT.CLASS_5:    
        value = 2.5 * f + 16;
        break;
      case CLASS_FIT.CLASS_6:
        value = 4.0 * f + 25;
        break;
      case CLASS_FIT.CLASS_7:
        value = 6.3 * f + 40;
        break;
    }
    
    // Convert to mm since it is in micrometers.    
    return value * 0.001;

  } // Ff


  /*

    Fb = Total lead variation
    Paragraph: 8.5

    Standard tolerance grade from ISO 1328

    Note: Value is in micrometer

  */
  get Fb()
  {
    let value = 0;
    //////////////////////////////////////////    TO DO   ///////////////////////////////////////
    let g = 0; // SPLINE LENGTH   !!!!!!!!!!!!!!!!!!!     !!!!!!!!!!!!!!!      !!!!!!!!!!!! ????
    switch (this.classFit) 
    {
      case CLASS_FIT.CLASS_4:
        value = 0.8 * Math.sqrt(g) + 4;
        break;
      case CLASS_FIT.CLASS_5:    
        value = 1.0 * Math.sqrt(g) + 5;
        break;
      case CLASS_FIT.CLASS_6:
       value = 1.25 * Math.sqrt(g) + 6.3;
       break;
      case CLASS_FIT.CLASS_7:
        value = 2.0 * Math.sqrt(g) + 10;
        break;
    }

    // Convert to mm since it is in micrometers.
    return value * 0.001;
  }


  /*

    lambda = Effective Variation: The combined effect of the total index, profile and tooth alignment
    have on the effective width of mating involute splines. 
    
    Paragraph: 8.1

  */
  get lambda()
  { 
/*
    let Fp = this.Fp * 1000;
    let Ff = this.Ff * 1000;
    let Fb = this.Fb * 1000;
*/
    return (0.6 * Math.sqrt(Math.pow(this.Fp,2) + Math.pow(this.Ff,2) + Math.pow(this.Fb,2)));
  }


  /*

    T = Machining Tolerance: The variation in actual circular space width or circular tooth thickness.
    
    Paragraph: 8.2

    Calculated by subtracting the effective variation (lambda) from the total tolerance (T + lambda)
    see par. 6.1 for (T + lambda) and 8.1 for lambda.

  */
  get T()
  {  
    return this.totalTolerance - this.lambda;
  }


  /*

    es = External spline circular tooth thickness modification
    Paragraph: 5.2 and 8.8.1

  */
  get es()
  {
  /*
    let D = 0;
    let d = 16 * Math.pow(D, 0.44)
    let e = 11 * Math.pow(D, 0.41)
    let f = 5.5 * Math.pow(D, 0.41)
  */
    let h = 0

    //////////////////////////////////////////    TO DO   ///////////////////////////////////////
    return h;
  }
 

  /*

    hs = Basic rack dimension
    Figures: 2A, 2B, 2C & 2D

  */
 get hs()
 {
    switch (this.pressureAngle)
    {
      case 30:
          return 0.6 * this.module;
      case 37.5:
        return 0.55 * this.module;
      case 45:
        return 0.5 * this.module;
      default:
        // ERROR - RETURN THE CASE 30 
        return 0.6 * this.module;
    }

 } // hs


  /*

    calcMajor()

  */
  calcMajor()
  {
    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {
      switch (this.pressureAngle)
      {
        case 30:
          this.maxMajor = this.module * (this.numTeeth + 1) - this.es / Math.tan(util.toRadians(this.pressureAngle));
          break;

        case 37.5:
          this.maxMajor = this.module * (this.numTeeth + 0.9) - this.es / Math.tan(util.toRadians(this.pressureAngle));
          break;

        case 45:
          this.maxMajor = this.module * (this.numTeeth + 0.8) - this.es / Math.tan(util.toRadians(this.pressureAngle));
          break;

        }

      // See table 6 for calculation
      this.minMajor = this.maxMajor - (0.2 * Math.pow(this.module, 0.667) - 0.01 * Math.pow(this.module, -0.5)); 
    }
    else // INTERNAL
    {
      switch (this.pressureAngle)
      {
        case 30:
          if (ROOT_TYPE.FLAT === this.rootType) {
            this.minMajor = this.module * (this.numTeeth + 1.5);
          }
          else {
            this.minMajor = this.module * (this.numTeeth + 1.8);            
          }
          break;

        case 37.5:
          this.minMajor = this.module * (this.numTeeth + 1.4);
          break;

        case 45:
          this.minMajor = this.module * (this.numTeeth + 1.2);
          break;

      } // switch

      // T = Machining Tolerance: The variation in actual circular space width or circular tooth thickness
      // lambda = Effective Variation: The combined effect of the total index, profile and tooth alignment. 

      // NOTE: FOR CLASS 7, WE MAY HAVE TO ONLY USE (this.T + this.lambda) and not divide by the tan(angle) = See NOTE 1
      this.maxMajor = this.minMajor + this.class7Tolerance() / Math.tan(util.toRadians(this.pressureAngle));

    } // if-else

  } // calcMajor


  /*

    calcMinor()

  */
  calcMinor()
  {
    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {
      switch (this.pressureAngle)
      {
        case 30:
          if (ROOT_TYPE.FLAT === this.rootType) {
            this.maxMinor = this.module * (this.numTeeth - 1.5) - this.es / Math.tan(util.toRadians(this.pressureAngle)); 
          }
          else {
            this.maxMinor = this.module * (this.numTeeth - 1.8) - this.es / Math.tan(util.toRadians(this.pressureAngle)); 
          }
          break;

        case 37.5:
          this.maxMinor = this.module * (this.numTeeth - 1.4) - this.es / Math.tan(util.toRadians(this.pressureAngle)); 
          break;

        case 45:
          this.maxMinor = this.module * (this.numTeeth - 1.2) - this.es / Math.tan(util.toRadians(this.pressureAngle)); 
          break;

      } // switch

      // NOTE: We need to calculate the total tolerance for Class 7 fit. That is the tolerance calculation that is used
      // for all minMinor calculations for external splines.      
      this.minMinor = this.maxMinor - this.class7Tolerance() / Math.tan(util.toRadians(this.pressureAngle));
    }
    else // INTERNAL
    {

      ////// MORE WORK HERE //// NOTE 2 ALSO SAYS THAT WE HAVE TO USE H/h FOR THE FIT. WE CURRENTLY
      ////// ARE NOT CALCULATING THE FIT MODIFICATION. SO WHEN WE DO, WE NEED TO CHANGE THIS AS WELL.

      // Note 2: of the spec says that when calculating the internal minMinor that we 
      // use the EXTERNAL form diamter calculation! So, we re-calculate the 
      // external form diameter here since the this.formDimeter is based on the INTERAL calc.
      let a = Math.pow((0.5 * this.baseDiameter), 2);
      let b = (0.5 * this.pitchDiameter * Math.sin(util.toRadians(this.pressureAngle)));
      let c = this.hs + ((0.5 * this.es) / Math.tan(util.toRadians(this.pressureAngle)));
      let d = Math.sin(util.toRadians(this.pressureAngle));
      let formDiameter =  2 * Math.sqrt(a + Math.pow(b - (c/d),2));
      
      this.minMinor = formDiameter + 2 * this.formClearance; // See Note 2;
      
      // See table 6 for calculation
      this.maxMinor = this.minMinor + (0.2 * Math.pow(this.module, 0.667) - 0.01 * Math.pow(this.module, -0.5)); 

    } // if-else

  } // calMinor


  /*

    calcFormDiameter()

  */
  calcFormDiameter()
  { 
    // EXTERNAL
    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {
      let a = Math.pow((0.5 * this.baseDiameter), 2);
      let b = (0.5 * this.pitchDiameter * Math.sin(util.toRadians(this.pressureAngle)));
      let c = this.hs + ((0.5 * this.es) / Math.tan(util.toRadians(this.pressureAngle)));
      let d = Math.sin(util.toRadians(this.pressureAngle));

      this.formDiameter =  2 * Math.sqrt(a + Math.pow(b - (c/d),2));
           
    }
    //  INTERNAL
    else
    {
        switch (this.pressureAngle)
        {
          case 30:
            this.formDiameter = this.module * (this.numTeeth + 1.0) + 2 * this.formClearance;
            break;

          case 37.5:
            this.formDiameter = this.module * (this.numTeeth + 0.9) + 2 * this.formClearance;
            break;

          case 45: 
            this.formDiameter = this.module * (this.numTeeth + 0.8) + 2 * this.formClearance;
            break;

        } // switch
    }

  } // calcFormDiameter


  /*

    calcTotalTolerance() = (T + lambda)

    Paragraph: 6.1

    Tolerance is based on the spline tolerance class (aka. 4, 5, 6, 7)

  */
  calcTotalTolerance()
  {
    
    let i;
    if (this.pitchDiameter <= 500) {
      i = 0.45 * Math.cbrt(this.pitchDiameter) + 0.001 * this.pitchDiameter;
    }
    else {
      i = 0.004 * this.pitchDiameter + 2.1;
    }
      
    let i2 = 0.45 * Math.cbrt(this.circularPitch / 2) + 0.001 * this.circularPitch / 2;

    switch (this.classFit) 
    {
      case CLASS_FIT.CLASS_4:
        this.totalTolerance =  10 * i + 40 * i2;       
        break;
      case CLASS_FIT.CLASS_5:    
        this.totalTolerance =  16 * i + 64 * i2;       
        break;
      case CLASS_FIT.CLASS_6:
        this.totalTolerance =  25 * i + 100 * i2;       
        break;
    case CLASS_FIT.CLASS_7:
      this.totalTolerance =  40 * i + 160 * i2;       
      break;
    }

    // Convert total tolerance to mm since it is in micrometers.
    this.totalTolerance *= 0.001;
  
  } // calcTotalTolerance


  // NOTE: We need to calculate the total tolerance for Class 7 fit. That is the tolerance calculation that is used
  // for all minMinor calculations for external splines and maxMajor for internal splines. This is due to "Note 1"
  // on from the spec calculations
  class7Tolerance()
  {
      let i;
      if (this.pitchDiameter <= 500) {
        i = 0.45 * Math.cbrt(this.pitchDiameter) + 0.001 * this.pitchDiameter;
      }
      else {
        i = 0.004 * this.pitchDiameter + 2.1;
      }
        
      let i2 = 0.45 * Math.cbrt(this.circularPitch / 2) + 0.001 * this.circularPitch / 2;  
      let tolerance =  40 * i + 160 * i2;       
      tolerance *= 0.001;  // // Convert total tolerance to mm since it is in micrometers.

      return tolerance;

  } // class7Toerance


  /*
    
    calcSpaceAndToothWidth()

    Actual Space Width = s : The circular width on the pitch circle of any single space considering an infinitely
    thin increment of axial spline length.

    Effective Space Width = Sv :  The effective space width of an internal spline is equal to the circular
    tooth thickness on the pitch circle of an imaginary perfect external spline which would fit the internal spline
    without looseness or interference considering engagement of the entire axial length of the spline. The minimum
    effective space width of the internal spline is always basic, as shown in Table 1. Fit variations may be obtained
    by adjusting the tooth thickness of the external spline.

    Actual Tooth Thickness = t : The circular thickness on the pitch circle of any single tooth considering an
    infinitely thin increment of axial spline length.

    Effective Tooth Thickness = tv : The effective tooth thickness of an external spline is equal to the circular
    space width on the pitch circle of an imaginary perfect internal spline which would fit the external spline without
    looseness or interference. considering engage· ment of the entire axial length of the spline.    

  */
  calcSpaceAndToothWidth()
  {
    var basic = this.circularPitch / 2;

    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {
      this.maxEffective = basic - this.es;
      this.minActual = this.maxEffective - this.totalTolerance;
      this.maxActual = this.maxEffective - this.lambda;
      this.minEffective = this.minActual + this.lambda;
    }
    else{
      this.minEffective = basic;
      this.maxActual = this.minEffective + this.totalTolerance;
      this.minActual = this.minEffective + this.lambda;  
      this.maxEffective = this.maxActual - this.lambda; 
    }

    this.width = (this.maxActual + this.minActual) / 2; // The defaul space width / tooth thickness is the average of the actual.

  } // calcSpaceAndToothWidth


  /*
    calcPins()

  We calculate three different pin dimeters. The diameter at pin 0 is the default
  one that the spec uses. However, we create two more just so the user has the ability
  to have different choice in case the don't have the specific pin. We also made a function
  called measurePins that allows the user to put in custom pin size and get a measurement.

  */
  calcPins(pins)
  {

    let DEE = 0;
    switch (this.pressureAngle)
    {
      case 30:
        DEE = this.module * (this.numTeeth + 1) - this.es / Math.tan(util.toRadians(this.pressureAngle));
        break;

      case 37.5:
        DEE = this.module * (this.numTeeth + 0.9) - this.es / Math.tan(util.toRadians(this.pressureAngle));
        break;

      case 45:
        DEE = this.module * (this.numTeeth + 0.8) - this.es / Math.tan(util.toRadians(this.pressureAngle));
        break;

    }

    // Note 2: of the spec says that when calculating the internal minMinor that we 
    // use the EXTERNAL form diamter calculation! So, we re-calculate the 
    // external form diameter here since the this.formDimeter is based on the INTERAL calc.
    let a = Math.pow((0.5 * this.baseDiameter), 2);
    let b = (0.5 * this.pitchDiameter * Math.sin(util.toRadians(this.pressureAngle)));
    let c = this.hs + ((0.5 * this.es) / Math.tan(util.toRadians(this.pressureAngle)));
    let d = Math.sin(util.toRadians(this.pressureAngle));
    let formDiameter =  2 * Math.sqrt(a + Math.pow(b - (c/d),2));
    
    let DII = formDiameter + 2 * this.formClearance; // See Note 2;
    let averageDiameter = (DEE + DII) / 2;
    let theta = Math.acos(this.baseDiameter / averageDiameter);
    let invThetaD = ( Math.tan(util.toRadians(this.pressureAngle)) - util.toRadians(this.pressureAngle) )
      
    // Calculate the size of the pin to use
    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {      
      let S = this.maxEffective - this.class7Tolerance(); // we re-calculate the minActual since we need it for tolerance class 7.
      let DE = this.basePitch - (S * Math.cos(util.toRadians(this.pressureAngle)) + this.baseDiameter * invThetaD);
      let BA = this.baseDiameter / 2 * Math.tan(theta);
      let y = DE / this.baseDiameter;
      let BO = this.baseDiameter / 2 * Math.tan(theta + invThetaD + y);
      this.pinDiameter = 2 * (BO - BA);
      
      this.width = this.minActual;
    }
    else 
    {
      let E = this.minEffective + this.class7Tolerance(); // we re-calculate the minActual since we need it for tolerance class 7.
      let DE = (E * Math.cos(util.toRadians(this.pressureAngle)) + this.baseDiameter * invThetaD);
      let BA = this.baseDiameter / 2 * Math.tan(theta);
      let y = DE / this.baseDiameter;
      let BO = this.baseDiameter / 2 * Math.tan(theta + invThetaD - y);
      this.pinDiameter = -2 * (BO - BA);

      this.width = this.maxActual;

    }

    // Find the closest matching pin to the ISO 3 - R40 pin set
    this.pinDiameter = ISO_R40.find((diameter)=>{
      return diameter > this.pinDiameter;
    })

    // Now calculate the measure over each the pin we just created
    this.pinMeasurement = involutePinMeasurements(this.numTeeth, this.pitchDiameter, this.baseDiameter, this.pressureAngle, this.splineType, this.width, this.pinDiameter);

  } // calcPins

}; // SplineValues


/* ISO Preferred Numbers

  R5: 10, 16, 25, 40, 63, 100.
  R10: 10, 12.5, 16, 20, 25, 31.5, 40, 50, 63, 80, 100.
  R20: 10, 11.2 12.5, 14, 16, 18, 20, 22.4, 25, 28, 31.5, 35.5, 40, 45, 50, 56, 63, 71, 80, 90, 100.
  R40: 10, 10.6, 11.2, 11.8,   12.5, 13.2, 14, 15, 16, 17, 18, 19, 20, 21.2, 22.4, 23.6, 25, 26.5, 28, 30, 31.5, 33.5, 35.5, 37.5, 40, 42.5, 45, 47.5, 50, 53, 56, 60, 63, 67,    71, 75, 80, 85, 90, 95, 100.

*/