import BaseShapeRenderer from "./shape-base"

export default class ScrShapeRenderer extends BaseShapeRenderer
{
	COLOR () {
		return {
			RED:	"1",
			YELLOW: "2",
			GREEN: 	"3",
			CYAN: 	"4",
			BLUE:	"5",
			BLACK:	"7"
		};
	}

	curve(profile)
	{
		var script = [];

		// Draw the profile
		script = script.concat(["SPLINE"]);
		for (var i = 0, len = profile.length; i < len; i++)
		{
  			script.push(profile[i].x + "," + profile[i].y); 
		}

		// Finish off the SPLINE command with three <ENTER>
		script = script.concat(
		[
			"",
			"",
			"",
		]);

		return script;
	}

	circle (x, y, radius)
	{
		return (["CIRCLE " + x + "," + y + " " + radius]);
	}

	arc(startX, startY, centerX, centerY, endX, endY)
	{
		return (
		[
			"ARC",
			startX + "," + startY,
			"C " + centerX + "," + centerY,
			endX + "," + endY,
		]);
	}

	layer(name, color)
	{
		return (
		[
			"-LAYER MAKE " + name, 
			"",
			"-LAYER COLOR " + color, 
			"",
			"",
		]);
	}

	line(startX, startY, endX, endY, color)
	{
		return (
			[
				"LINE",
				startX + "," + startY,
				endX + "," + endY,
				"",
			]);
	}

	header()
	{
		return (
			[
				"OSMODE",
				"0",
			]
		);
	}

	footer()
	{
		return (
			[
				"REGEN",
				"",
				"POINT",
				"0,0",
				"ZOOM",
				"EXTENTS",
				"",
				""
			]
		);
	}

}
