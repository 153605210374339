
export function CalculationException (specification, value, message)
{
  this.value = value;
  this.message = message;
  this.specification = specification;
  this.toString = function() {
     return this.specification + ": " + this.message;
  };
}

export class CalculationBase
{  
    constructor(specification)
    {
        this.specification = specification;
    }

    throwError(value, message)
    {
        throw new CalculationException(this.specification, value, message);   
    }

} // SpecEngine
