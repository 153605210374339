import React from 'react';
import {PreviewRenderer, SCALE_FACTOR} from "./shape-preview"
import './css/drawing.css'; 


export default class Drawing extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			splineValues: props.splineValues
		}
	}

	// This is needed to update the drawing with the calculated values that are coming 
	// from the spline-input component. If we don't do this, the drawing is never
	// refreshed after the spline values are calculated.
	static getDerivedStateFromProps(nextProps, prevState)
	{
		return nextProps;
	}

	render()
	{
		if (this.state.scriptGenerator)
		{
			let renderer = new PreviewRenderer();
			var script = this.props.scriptGenerator.createScript(renderer);

			// Default to using the max major as the size of the drawing. However, if we have an external
			// drawing, the pins are on the outside. So, use the pin measurement if it is more than the
			// max major.
			let width = this.state.splineValues.maxMajor;
			let pinMeasurement = this.state.splineValues.pinCenterRadius ?  this.state.splineValues.pinCenterRadius * 2 + this.state.splineValues.pinDiameter : width;
			if (width < pinMeasurement) {
				width = pinMeasurement;
			}

			// Since the splines are so small, we multiply all the valyes by a SCALE_FACTOR. That way the drawing is
			// higher resolution and looks better when rendered in SVG.
			width *= SCALE_FACTOR;

			var boxStr = -width/2 + " " + -width/2 + " " + width + " " + width;
			return (
				<div className='svg-container'>
					<div className='svg-inner'>
						<svg preserveAspectRatio="xMidYMid" transform="scale(1,-1)" viewBox={boxStr} >
							{script}
						</svg>
					</div>
				</div>		
			);
		}
		else {
			return ( <div /> );
		}
	}
}
