
export const SIDE = {
	LEFT	: -1,
	RIGHT : 1
  };
  
export default class BaseProfile
{
	constructor()
	{
		this.profile = [];

	} 

	get start()
	{
		return this.profile[0];
	}

	get end()
	{
		return this.profile[this.profile.length - 1];
	}

} // BaseProfile

