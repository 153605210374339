import {CalculationBase} from "./calc-base"
import * as util from './utilities';

export {CalculationException} from "./calc-base"

export const NOMINAL = [
    '1/8', '3/16', '1/4', '5/16', '3/8', '1/2', '5/8', '3/4', '7/8', '1', '1-1/8', '1-1/4', '1-3/8', '1-1/2', '1-3/4', '2', '2-1/4', '2-1/2', '2-3/4', '3'
];

export const SPLINE_TYPE = { 
  HOLE    : "Hole",
  SHAFT 	: "Shaft" 
};

const SPEC_VALUES = {
    '1/8': {pitch: 0.122, maxSmall: 0.118, minSmall: 0.1113, maxRoot: 0.1160, maxOutside: 0.1240, minOutside: 0.1230},
    '3/16': {pitch: 0.182, maxSmall: 0.176, minSmall: 0.1750, maxRoot: 0.1740, maxOutside: 0.1860, minOutside: 0.1850},
    '1/4': {pitch: 0.243, maxSmall: 0.235, minSmall: 0.2340, maxRoot: 0.2330, maxOutside: 0.2490, minOutside: 0.2480},
    '5/16': {pitch: 0.303, maxSmall: 0.293, minSmall: 0.2920, maxRoot: 0.2910, maxOutside: 0.3110, minOutside: 0.3100},
    '3/8': {pitch: 0.363, maxSmall: 0.352, minSmall: 0.3510, maxRoot: 0.3500, maxOutside: 0.3740, minOutside: 0.3730},
    '1/2': {pitch: 0.485, maxSmall: 0.469, minSmall: 0.4680, maxRoot: 0.4670, maxOutside: 0.4990, minOutside: 0.4980},
    '5/8': {pitch: 0.605, maxSmall: 0.584, minSmall: 0.5830, maxRoot: 0.5820, maxOutside: 0.6240, minOutside: 0.6230},
    '3/4': {pitch: 0.733, maxSmall: 0.716, minSmall: 0.7140, maxRoot: 0.7130, maxOutside: 0.7490, minOutside: 0.7470},
    '7/8': {pitch: 0.855, maxSmall: 0.835, minSmall: 0.8330, maxRoot: 0.8320, maxOutside: 0.8740, minOutside: 0.8720},
    '1': {pitch: 0.977, maxSmall: 0.954, minSmall: 0.9520, maxRoot: 0.9510, maxOutside: 0.9990, minOutside: 0.9970},
    '1-1/8': {pitch: 1.098, maxSmall: 1.071, minSmall: 1.0690, maxRoot: 1.0680, maxOutside: 1.1240, minOutside: 1.1220},
    '1-1/4': {pitch: 1.220, maxSmall: 1.190, minSmall: 1.1880, maxRoot: 1.1870, maxOutside: 1.2490, minOutside: 1.2470},
    '1-3/8': {pitch: 1.342, maxSmall: 1.309, minSmall: 1.3070, maxRoot: 1.3060, maxOutside: 1.3740, minOutside: 1.3720},
    '1-1/2': {pitch: 1.464, maxSmall: 1.428, minSmall: 1.4260, maxRoot: 1.4250, maxOutside: 1.4990, minOutside: 1.4970},
    '1-3/4': {pitch: 1.708, maxSmall: 1.660, minSmall: 1.6640, maxRoot: 1.6630, maxOutside: 1.7490, minOutside: 1.7470},
    '2': {pitch: 1.952, maxSmall: 1.904, minSmall: 1.9020, maxRoot: 1.9010, maxOutside: 1.9990, minOutside: 1.9970},
    '2-1/4': {pitch: 2.196, maxSmall: 2.142, minSmall: 2.1400, maxRoot: 2.1390, maxOutside: 2.2490, minOutside: 2.2470},
    '2-1/2': {pitch: 2.440, maxSmall: 2.380, minSmall: 2.378, maxRoot: 2.3770, maxOutside: 2.4990, minOutside: 2.4970},
    '2-3/4': {pitch: 2.684, maxSmall: 2.618, minSmall: 2.6160, maxRoot: 2.6150, maxOutside: 2.7490, minOutside: 2.7470},
    '3': {pitch: 2.928, maxSmall: 2.859, minSmall: 2.7258, maxRoot: 2.8530, maxOutside: 2.9990, minOutside: 2.9970}
};


export class SplineValues extends CalculationBase
{
  constructor(nominalDiameter, splineType)
  {
    super("SAE J500");
    
    this.nominalDiameter = nominalDiameter;
    this.splineType = splineType;

    // Check to make sure that the parameters are inline with the specification.
    this.checkParams(nominalDiameter, splineType);

    this.calcTeethAndAngle();
    this.calcDiameters();
    this.calcPins();    

  } // constructor


  checkParams(nominalDiameter, splineType)
  {
    switch(splineType)
    {
      case SPLINE_TYPE.HOLE:
      case SPLINE_TYPE.SHAFT:
        break;
      default:
        this.throwError(splineType, "Spline type must be " + SPLINE_TYPE.HOLE + " or " + SPLINE_TYPE.SHAFT);
    }
  } // checkParams
     
  
  calcTeethAndAngle()
  {
    switch (this.nominalDiameter)
    {
        case '1/8':
        case '3/16':
        case '1/4':
        case '5/16':
        case '3/8':
        case '1/2':
        case '5/8':
            this.numTeeth = 36;
            this.includedAngle = 80;
            break;
        default:
            this.numTeeth = 48;
            this.includedAngle = 82.5;
            break;
    }

  } // calcTeeth


  calcDiameters()
  {
    var split = this.nominalDiameter.split('/');
    this.minLarge = parseInt(split[0], 10) / parseInt(split[1], 10);

    let val = SPEC_VALUES[this.nominalDiameter];
    this.pitchDiameter = val.pitch;    
    this.maxSmall = val.maxSmall;
    this.minSmall = val.minSmall;
    this.maxRoot = val.maxRoot;
    this.maxOutside = val.maxOutside;
    this.minOutside = val.minOutside;

    switch(this.splineType)
    {
      case SPLINE_TYPE.HOLE:
        this.maxMajor = this.minLarge; // the spec has no max
        this.minMajor = this.minLarge; 
        this.maxMinor = this.maxSmall;
        this.minMinor = this.minSmall; 
        break
      case SPLINE_TYPE.SHAFT:
        this.maxMajor = this.maxOutside;
        this.minMajor = this.minOutside;
        this.maxMinor = this.maxRoot;
        this.minMinor = this.maxRoot; // The spec has no min.
      break;
    }

    if (this.numTeeth === 36) {
        this.od = 1.0476479 * this.pitchDiameter;
        this.rd = 0.9523521 * this.pitchDiameter;
    }
    else {
        this.od = 1.0349592 * this.pitchDiameter;
        this.rd = 0.9650408 * this.pitchDiameter;
    }  

  } // calcDiameters


  calcPins()
  {
    if (SPLINE_TYPE.SHAFT === this.splineType)
    {
        if (this.numTeeth === 36)
        {
            this.pinDiameter = 0.06565005 * this.pitchDiameter;
            this.pinMeasureMax = 1.1113285 * this.pitchDiameter;
        }
        else
        {
            this.pinDiameter = 0.0485955 * this.pitchDiameter;
            this.pinMeasureMax = 1.0823601 * this.pitchDiameter;
        }

        this.pinTolerance = .001 * (Math.sin(util.toRadians(45 - 180 / this.numTeeth)) / Math.sin(util.toRadians(45)));
        this.pinMeasureMin = this.pinMeasureMax - this.pinTolerance;
    }
    else
    {
        if (this.numTeeth === 36)
        {
            this.pinDiameter = 0.05309792 * this.pitchDiameter;
            this.pinMeasureMin = 0.9119441 * this.pitchDiameter;
        }
        else
        {
            this.pinDiameter = 0.04133332 * this.pitchDiameter;
            this.pinMeasureMin = 0.9309375 * this.pitchDiameter;
        } 
        
        this.pinTolerance = .001 * (Math.sin(util.toRadians(45)) / Math.sin(util.toRadians(45 - 180 / this.numTeeth)));
        this.pinMeasureMax = this.pinMeasureMin + this.pinTolerance;
    }

  } // calcPins

}; // SplineValues
