import React from 'react';
import './css/overridable-input.css';

var classNames = require('classnames');

const DEFAULT_PRECISION = 4;

export default class OverridableInput extends React.Component
{
	constructor(props)
	{
		super(props);
		this.precision = props.precision ? props.precision : DEFAULT_PRECISION;
		let value = props.value;
		let formattedValue =  value.toFixed(this.precision);

		this.state = {
			value: 			formattedValue,
			precision: 		this.precision,
			displayValue: 	!isNaN(props.value) ? parseFloat(props.value.toFixed(this.precision)) : props.value,
			isEditing:		false,
			isOverridden: 	props.overridden			
		};

		// This binding is necessary to make `this` work in the callback
	    this.handleBlur = this.handleBlur.bind(this); 
		this.handleChange = this.handleChange.bind(this);
		this.enterPressed = this.enterPressed.bind(this);
	}

	
	handleBlur(event)
	{	
		let newValue = Number(event.target.value);	
		let formattedValue  = newValue.toFixed(this.state.precision);
		this.setState({
			value:			newValue,
			displayValue: 	formattedValue,
			isEditing:		false
		});

		console.log("HandleBlur:" + newValue);

		// Notify the parent of the new change
		if (this.props.onBlur)
		{
			this.props.onBlur(this.props.id, newValue);
		} 

	} // handleBlur
	

	handleChange(event)
	{
		event.target.id = this.props.id;
		let newValue = event.target.value;
		console.log("Component: " + this.props.id + " Value: " + newValue);

		this.setState({
			value: 		 	Number(newValue),
			isEditing: 		true
		});

	} // handleChange
	

	enterPressed(event)
	{
		var code = event.keyCode || event.which;
		if (code === 13) { // 13 is the enter keycode
			this.handleBlur(event);
		} 

	} // enterPressed


	static getDerivedStateFromProps(nextProps, prevState)
	{	
		let newValue = 0;
		if (true === prevState.isEditing)
		{
			newValue = prevState.value;
		}
		else
		{
			newValue = nextProps.value;
		}

		if (newValue === undefined) {
			debugger;
		}

		let precision = prevState.precision !== undefined ? prevState.precision : DEFAULT_PRECISION;
		let formattedValue =  newValue.toFixed(precision);

		return {
			value: 			newValue,
			displayValue: 	formattedValue,
			isOverridden: 	nextProps.overridden
		}

	} // getDerivedStateFromProps


	render()
	{
		const value = this.state.displayValue;
	  	var inputClass = classNames({
			'overridden': this.props.overridden
		});
		 
	    return (
			<div>
				<input
					disabled={this.props.disabled}
					className={inputClass}
					onKeyPress={this.enterPressed}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					value={value} >
				</input>
			</div>
		);

	 } // render
	 
} // class OverridableInput

