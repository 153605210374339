import React from 'react';
import ValuesBase from './values-base';
import SerrationScriptGenerator from "./script-serration"

import OverridableInput from './overridable-input';
import * as util from './utilities';
import * as j500 from './calc-j500';
import './css/values.css';


export default class J500Values extends ValuesBase
{
	constructor(props)
	{
		super(props);     
		this.state = {
			nominalDiameter:    '1/8',
			splineType:         j500.SPLINE_TYPE.SHAFT
		};
	}


    // Calculate the spline values
    calculateValues()
    {		
        let splineValues = new j500.SplineValues(this.state.nominalDiameter, this.state.splineType);

        this.setState({
			major: splineValues.maxMajor,
			minor: splineValues.maxMinor,
			width: splineValues.averageActual	
		});

        return splineValues;
        
    } // calculateValues
   

	// createScript knows which script generator to use based on the type of values and 
	// calculation engine that this object represents. So, when we need a scipt generated
	// we just as the object to create me a script generator of the appropriate type and 
	// generate the script.
	createScript(renderer)
	{
		let scriptGeneator = new SerrationScriptGenerator(this.state, renderer);	
		return scriptGeneator.createScript();
    }
    
    
    displayHoleShaftValues()
    {
        if (j500.SPLINE_TYPE.SHAFT === this.state.splineType)
        {
            return (
                <div className="input-values">
                    <label htmlFor="maxRoot">Max Root Dia.</label>
                    <OverridableInput type="number" id="maxRoot" value={this.state.maxRoot} onBlur={this.handleBlur} />
    
                    <label htmlFor="maxOutside">Max Outside Dia.</label>
                    <OverridableInput type="number" id="maxOutside" value={this.state.maxOutside} onBlur={this.handleBlur} />
    
                    <label htmlFor="minOutside">Min Outside Dia.</label>
                    <OverridableInput type="number" id="minOutside" value={this.state.minOutside} onBlur={this.handleBlur} />    
                </div>
            );
        }
        else {
            return (
                <div className="input-values">
                    <label htmlFor="minLarge">Min Large Dia.</label>
                    <OverridableInput type="number" id="minLarge" value={this.state.minLarge} onBlur={this.handleBlur} />
    
                    <label htmlFor="maxSmall">Max Small Dia.</label>
                    <OverridableInput type="number" id="maxSmall" value={this.state.maxSmall} onBlur={this.handleBlur} />
    
                    <label htmlFor="minSmall">Min Small Dia.</label>
                    <OverridableInput type="number" id="minSmall" value={this.state.minSmall} onBlur={this.handleBlur} />
                </div>
            );
        }
    }

    
	render()
	{
	    return (
            <div>					
                <div className="input-values">

                    <label htmlFor="nominalDiameter">Nominal Diameter</label>
                    <select id="nominalDiameter" value={this.state.nominalDiameter} onChange={this.handleSelectChange} >
                        {util.createSelectItems(j500.NOMINAL)}
                    </select>
                    
                    <label htmlFor="splineType">Type</label>
                    <select id="splineType" value={this.state.splineType} onChange={this.handleSelectChange} >
                        {util.createSelectItems(j500.SPLINE_TYPE)}
                    </select>

                </div>

                <div>
                <h4>Calculated Values:</h4>
                <p className="App-tips">
                    Override the calculated values by entering a value into the field. To reset the override back to the original value, erase the value and exit the field.
                </p>
                </div>

                <div className="input-values">

                    <label htmlFor="numTeeth">Teeth</label>
                    <OverridableInput type="number" id="numTeeth" precision="0" value={this.state.numTeeth} onBlur={this.handleBlur} />

                    <label htmlFor="pitchDiameter">Pitch Diameter</label>
                    <OverridableInput type="number" id="pitchDiameter" value={this.state.pitchDiameter} onBlur={this.handleBlur} />

                    <label htmlFor="includedAngle">Angle</label>
                    <OverridableInput type="number" id="includedAngle" precision="1" value={this.state.includedAngle} onBlur={this.handleBlur} />

                    <label htmlFor="od">OD</label>
                    <OverridableInput type="number" id="od" value={this.state.od} onBlur={this.handleBlur} />

                    <label htmlFor="rd">RD</label>
                    <OverridableInput type="number" id="rd" value={this.state.rd} onBlur={this.handleBlur} />

                </div>

                {this.displayHoleShaftValues()}

                <div>
                    <h4>Pin Dimensions:</h4>
                    <p className="App-tips">
                        To calculate dimension for other pin diameters, override the pin diameter by entering a value into the pin diameter field. To reset the override back to the original value, erase the value and exit the field.
                    </p>
                </div>

                <div className="input-values">
                        <label htmlFor="pin1Diameter">Pin Diameter</label>
                        <OverridableInput id="pin1Diameter" value={this.state.pinDiameter} />

                        <label htmlFor="minPin1Measure">Min Dimension</label>
                        <OverridableInput disabled id="minPin1Measure" value={this.state.pinMeasureMin}/>

                        <label htmlFor="maxPin1Measure">Max Dimension</label>
                        <OverridableInput disabled id="maxPin1Measure" value={this.state.pinMeasureMax}/>
                </div>
            </div>

	  	);
	}

} // J500Values