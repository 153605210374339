import React from 'react';


export class Point
{
	constructor(x, y, angle = undefined)
	{
		this.x = x;
		this.y = y;
		this.angle = angle;
	}

	get length()
	{
		return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
	}
} // Point


export function createSelectItems(items)
{        
    let options = [];
    for (const prop in items) {
            options.push(<option key={prop} value={items[prop]}>{items[prop]}</option>); 
    }
    return options;

} // createSelectItems

export function isEven(num) { return !isOdd(num); }
export function isOdd(num) { if(num & 1) { return true; } else { return false; } }

export function toDegrees (angle) { return angle * (180 / Math.PI); }
export function toRadians (angle) { return angle * (Math.PI / 180); }

export function findKeyForValue(obj, value)
{
	for (const prop in obj) {
		if(obj[prop] === value)
			return prop;
	}
	return null;
}

export function isEquivalent(a, b)
{
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}


export function lineCircleIntersect(pt1, pt2, radius)
{
    let Ax = pt1.x;
    let Ay = pt1.y;
    let Bx = pt2.x;
    let By = pt2.y;

    let Cx = 0;
    let Cy = 0;

    // compute the euclidean distance between A and B
    let LAB = Math.sqrt( Math.pow((Bx-Ax), 2) + Math.pow((By-Ay), 2));

    // compute the direction vector D from A to B
    let Dx = (Bx-Ax)/LAB;
    let Dy = (By-Ay)/LAB;

    // Now the line equation is x = Dx*t + Ax, y = Dy*t + Ay with 0 <= t <= 1.

    // compute the value t of the closest point to the circle center (Cx, Cy)
    let t = Dx*(Cx-Ax) + Dy*(Cy-Ay);  

    // This is the projection of C on the line from A to B.

    // compute the coordinates of the point E on line and closest to C
    let Ex = t*Dx+Ax;
    let Ey = t*Dy+Ay;

    // compute the euclidean distance from E to C
    let LEC = Math.sqrt( Math.pow((Ex-Cx), 2) + Math.pow((Ey-Cy),2));

    // test if the line intersects the circle
    if( LEC < radius)
    {
        // compute distance from t to circle intersection point
        let dt = Math.sqrt( Math.pow(radius , 2) - Math.pow( LEC, 2));

        // compute first intersection point
        let Fx = (t-dt)*Dx + Ax;
        let Fy = (t-dt)*Dy + Ay;

        // compute second intersection point
/*
        let Gx = (t+dt)*Dx + Ax;
        let Gy = (t+dt)*Dy + Ay;
*/
        return new Point(Fx, Fy, 0);
    }
    // else test if the line is tangent to circle
    else if( LEC === radius )
    {
        // tangent point to circle is E
    }
    else {
        // line doesn't touch circle
    }

} // lineCircleIntersect


export function involuteCircleIntersect(baseDiameter, circleDiameter)
{
	let x = 0;
	let y = 0;
	let t = 0;
	let angle = 0;

	// If the circle we are trying to intersect with the involute is smaller than
	// the base diameter, then there is no involute form that low and, thus, there
	// will never be an intersection. As such, just return a point on the circle that
	// is a line interesecting the origin at the specified...???
	if (circleDiameter >= baseDiameter) {
		/*
			Calculate the intersection of an involute and a circle where the angle
			in radians of the involute curve at that point.
		*/
		let baseRadius = baseDiameter / 2;
        let circleRadius = circleDiameter / 2;
        
        // Calculate the involute angle that intersects the circle by using
        // https://www.physicsforums.com/threads/intersection-between-involute-curve-and-a-circle.256932/
        angle = Math.sqrt( Math.pow(circleRadius / baseRadius, 2) - 1 );
        
        // Calculate the pont of interesection by plugging the angle back into the involute equation
		x = baseRadius * (Math.cos(angle) + angle * Math.sin(angle));	// x of involute curve at angle			
        y = baseRadius * (Math.sin(angle) - angle * Math.cos(angle));	// y of involute curve at angle			

        // t is the angle from the x axis that this point starts at. It can be used to rotate the curve so it this point is 
        // centered with the axis. 
		t = toDegrees(Math.atan2(y,x)); 
	}
	
	var pt = new Point(x, y, toDegrees(angle));
	pt.t = t;
	return pt;

} // involuteCircleIntersect

