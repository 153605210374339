import {CalculationBase} from "./calc-base"
import * as util from './utilities';

export {CalculationException} from "./calc-base"

export const SPLINE_TYPE = { 
    INTERNAL	: "Internal",
    EXTERNAL 	: "External" 
  };
  
export const FIT_TYPE = {
    FLAT		: "Flat Root, Side Fit",
    FILLET 	: "Fillet Root, Side Fit",
    MAJOR		: "Flat Root, Major Dia. Fit"
};

export const CLASS_FIT = {
    CLASS_4 : "Class 4",
    CLASS_5	: "Class 5",
    CLASS_6	: "Class 6",
    CLASS_7	: "Class 7"
};

export const PRESSURE_ANGLE = [
  30, 37.5, 45
];

/*
    measurePins(numTeeth, type, actual, pinDiameter)

    numTeeth = number of teeth for this spline, specified by the user.
    pitchDiameter = Spline pitch diameter
    baseDiameter = Spline base diameter
    pressureAngle = Splline pressure angle
    splineType = Spline type as either internal or external
    width = Actual tooth thicknes or space width
    pinDiameter = Diameter of measuring pin we want to calculate the pin measurement for.

  */
 export function involutePinMeasurements(numTeeth, pitchDiameter, baseDiameter, pressureAngle, splineType, width, pinDiameter)
 {
   /*
     Note: When calculating the pin locations, we need the minor and major diameters as show in the spec.
     The spec always shows the minimum minor and the maximum major.
   */
   var M_ie = 0;
   var invTheta_ie = 0;
   if (SPLINE_TYPE.INTERNAL === splineType)
   {
     pinDiameter = -pinDiameter;
     invTheta_ie = width / pitchDiameter + ( Math.tan(util.toRadians(pressureAngle)) - util.toRadians(pressureAngle) ) + pinDiameter / baseDiameter;

   }
   else // EXTERNAL
   {
     invTheta_ie = width / pitchDiameter + ( Math.tan(util.toRadians(pressureAngle)) - util.toRadians(pressureAngle) ) + pinDiameter / baseDiameter - Math.PI / numTeeth;
   }

   // Calculate Theta_ie from invTheta_ie

   /* 
     We are going to calculate the tooth thickness at the point of contact. Because there is no direct method
     for determination of tooth thickness at any point along an involute curve, several approximations
     have been developed. See http://gearsolutions.com/features/calculating-the-inverse-of-an-involute/

     Here we use the Irving Laskin technique.

     For involute angles up to 30 degrees with two approximations, there is no error to six significant digits.
     With four approximations this is true to 64.87 degrees.
   */

   // Calculate the first approximation
   var f1 = 1.441 * Math.cbrt(invTheta_ie) - (0.374 * invTheta_ie);

   // Calculate the second approximation
   var f2 = f1 + (invTheta_ie - ( Math.tan( f1 ) - f1 ) ) / ( Math.pow(Math.tan(f1),2));

   // Calculate the thrid approximation
   var f3 = f2 + (invTheta_ie - ( Math.tan( f2 ) - f2 ) ) / ( Math.pow(Math.tan(f2),2));

   // Calculate the fourth approximation
   var Theta_ie = f3 + (invTheta_ie - ( Math.tan( f3 ) - f3 ) ) / ( Math.pow(Math.tan(f3),2));
   
/*
   This was the old calculation and is what is in the spec. However, we don't use this since we need to know where to draw the pins
   as well as the measure over them when they are not across from each other. So, we always calculate like there are an even number
   of pins and then we compensat for the pins not being directly across from each other if it is odd
*/
   if (util.isEven(numTeeth))
   {
       M_ie = baseDiameter * (1 / Math.cos(Theta_ie)) + pinDiameter;
   }
   else
   { 
       M_ie = (baseDiameter * Math.cos( util.toRadians( 90 / numTeeth ) ) ) * ( 1 / Math.cos(Theta_ie)) + pinDiameter;
   }

  // Default to an even number of teeth and set the radius to the center of the pin M_ie / 2. This is used to get the radius from the
  // center of the spline circle to the center of where the pin should be regardless if the pins are over even or odd teeth.
   var pinCenterRadius = baseDiameter * (1 / Math.cos(Theta_ie)) / 2; 
  
   // NOTE: Dci is the diameter of where the pin touches the involute. We currenlty don't use it and thus
   // only pass back the pin measure at this point. However, we calculate it here since it's such a pain
   // to figure out in the future if we ever do want to use it.

   // Calculate the conctact diameter of the pin from FIG 23 and FIG 24 of Section 21
/*
   var tanDci = Math.tan(Theta_ie) - pinDiameter / baseDiameter; // Note: the sign of the pin diameter is already changed via external or internal, above.
   var Theta_ci = Math.atan(tanDci);
   var Dci = baseDiameter * (1 / Math.cos(Theta_ci));
*/ 

   // Return both the measure over or under the pins so we know what the diamter is to draw the pins as well as the cord diameter across the pins for measurements.
   return {
      pinDiameter:      Number(pinDiameter),
      pinMeasurement: 	Number(M_ie),
      pinCenterRadius:  Number(pinCenterRadius)
    };
     
 } // involutePinMeasurements


 // NOTE: NOT PROVEN TO WORK YET!!!!
 /*
 calculateFilletRadius()
 {
   // Calculate the servolute based on the artilce from gear solutions
   // https://gearsolutions.com/features/calculating-the-inverse-of-an-involute/

   var filletRadius = 0;

   var S = this.servolute(this.actualPressureAngle);
   var e1 = 0.8 * (S - 1) + 1.4 * Math.sqrt(S - 1);
   var S_e1 = this.servolute(e1);
   var e2 = e1 + (S - S_e1) * (1 + (1 / Math.sin(util.toRadians(e1))));
   var S_e2 = this.servolute(e2);   
   var e3 = e2 + (S - S_e2) * ( 1 + (1 / Math.sin(util.toRadians(e2))));

   if (SPLINE_TYPE.INTERNAL === this.splineType)
   {
     filletRadius = 0.5 * (this.maxMajor - this.baseDiameter * (1 / Math.cos(util.toRadians(this.actualPressureAngle))));
   }
   else // EXTERNAL
   {
     filletRadius = 0.5 * (this.baseDiameter * (1 / Math.cos(util.toRadians(this.actualPressureAngle))) - this.minMinor );
   }

   return filletRadius / 2;   

 }
*/

export class SplineValues extends CalculationBase
{
  constructor(numTeeth, diametralPitch, pressureAngle, splineType, fitType, classFit)
  {
    super("ANSI B92.1-1996");

    // Make sure any strings passed in are converted to numbers;
    numTeeth = Number(numTeeth);
    diametralPitch = Number(diametralPitch);
    pressureAngle = Number(pressureAngle);
    
    // Check to make sure that the parameters are inline with the specification.
    this.checkParams(numTeeth, diametralPitch, pressureAngle, splineType, fitType, classFit);

    this.numTeeth = numTeeth;
    this.diametralPitch = diametralPitch;
    this.actualPressureAngle = pressureAngle;
    this.splineType = splineType;
    this.fitType = fitType;
    this.classFit = classFit;
    this.stubPitch = 2 * this.diametralPitch;
    this.pitchDiameter = this.numTeeth / this.diametralPitch;
    this.baseDiameter = this.pitchDiameter * Math.cos(util.toRadians(this.actualPressureAngle));
    this.basePitch = Math.PI / this.diametralPitch * Math.cos(util.toRadians(this.actualPressureAngle));
    this.circularPitch = Math.PI / this.diametralPitch;
    this.minEffSpcWidth = Math.PI / (2 * this.diametralPitch);

    this.calcTolerance();
    this.calcFormDiameter();
    this.calcMinor();
    this.calcMajor();
    this.calcSpaceAndToothWidth();
    this.toothHeight = (this.maxMajor - this.maxMinor) / 2;
//    this.filletRadius = this.calculateFilletRadius();

    this.calcPins();

  } // constructor

  /*
    checkParams will make sure that all of the values passed in match the ANSI standard and this
    spline follows the specification. If not, it throws an error with a message and the value.
  */  
  checkParams(numTeeth, diametralPitch, pressureAngle, splineType, fitType, classFit)
  {
    if ((numTeeth < 6) || (numTeeth > 100))
      this.throwError(numTeeth, "Number of teeth canont be less than 6 or greater than 100");
    
    if ((diametralPitch < 2.5) || (diametralPitch > 128))
      this.throwError(diametralPitch, "Diametral Pitch can not be less than 2.5 or greater than 128");
    
    switch (pressureAngle)
    {
      case 30:
      case 37.5:
      case 45:
        break;
      default:
        this.throwError(pressureAngle, "Pressure angle must be 30, 37.5 or 45 degrees");
    }

    switch(splineType)
    {
      case SPLINE_TYPE.EXTERNAL:
      case SPLINE_TYPE.INTERNAL:
        break;
      default:
        this.throwError(splineType, "Spline type must be " + SPLINE_TYPE.INTERNAL + " or " + SPLINE_TYPE.EXTERNAL);  
    }

    switch (fitType)
    {
      case FIT_TYPE.FLAT:
      case FIT_TYPE.FILLET:
      case FIT_TYPE.MAJOR:
        break;
      default: 
        this.throwError(fitType, "Fit type must be " + FIT_TYPE.FLAT + ", " + FIT_TYPE.FILLET + " or " + FIT_TYPE.MAJOR);
    }

    switch (classFit) 
    {
      case CLASS_FIT.CLASS_4:
      case CLASS_FIT.CLASS_5:    
      case CLASS_FIT.CLASS_6:
      case CLASS_FIT.CLASS_7:
        break;
      default: 
        this.throwError(classFit, "Class fit must be " + CLASS_FIT.CLASS_4 + ", " + CLASS_FIT.CLASS_5 + ", " + CLASS_FIT.CLASS_6 + " or " + CLASS_FIT.CLASS_7);
      break;
    }

  } // checkParams

  
  /*
    GETTER: specPressureAngle()
    
    The ANSI spec only allows for 30, 37.5 and 45 degree pressure angles and knowing
    which calculation to use in the spec depends on these three angles. Therefore,
    we want to calculate a pressure angle that matches the spec pressure agle so we
    know which calculation to use. However, this is only to know which calculation to use.
    We sill use the actualPressureAngle that was given by the user when doing the calculation.

  */
  get specPressureAngle()
  {
    if (this.actualPressureAngle >= 45) { return 45; }
    else if (this.actualPressureAngle >= 37.5) { return 37.5; }
    else { return 30; }

  } // specPressureAngle


  /*

    GETTER: formClearance()

    2.33 Form Clearance = Cf : The radial depth of involute profile beyond the depth of engagement
    with the mating part. It allows for looseness between mating splines and for eccentricities
    between the minor circle (internal), the major circle (external), and their respective pitch circles.

  */
  get formClearance()
  {
    return Math.max(Math.min(0.001 * this.pitchDiameter, 0.010), 0.002);

  } // formClearance


  /*

    calcMajor()

    2.20 Major Diameter = Do, Dri : The diameter of the major circle.

    Major Circle : The circle formed by the outer- most surface of the spline. It is the outside
    circle (tooth tip circle) of the external spline or the root circle of the internal spline.

  */
  calcMajor()
  {
    if (SPLINE_TYPE.EXTERNAL === this.splineType)
    {
      var tolerance = 0;
      switch (this.fitType)
      {
        case FIT_TYPE.FLAT:
          if (this.diametralPitch <= 2.5) { tolerance = .1050; }
          else if (this.diametralPitch <= 3) { tolerance = .0870; }
          else if (this.diametralPitch <= 4) { tolerance = .0650; }
          else if (this.diametralPitch <= 5) { tolerance = .0530; }
          else if (this.diametralPitch <= 6) { tolerance = .0430; }
          else if (this.diametralPitch <= 8) { tolerance = .0350; }
          else if (this.diametralPitch <= 10) { tolerance = .0300; }
          else if (this.diametralPitch <= 12) { tolerance = .0270; }
          else if (this.diametralPitch <= 16) { tolerance = .0220; }
          else if (this.diametralPitch <= 20) { tolerance = .0200; }
          else if (this.diametralPitch <= 24) { tolerance = .0180; }
          else { tolerance = .0140; }

          this.maxMajor = (this.numTeeth + 1) / this.diametralPitch;
          this.minMajor = this.maxMajor - tolerance;
          break;

        case FIT_TYPE.FILLET:
          if (this.diametralPitch <= 2.5) { tolerance = .0200; }
          else if (this.diametralPitch <= 3) { tolerance = .0150; }
          else if (this.diametralPitch <= 4) { tolerance = .0100; }
          else if (this.diametralPitch <= 5) { tolerance = .0080; }
          else if (this.diametralPitch < 32) { tolerance = .0050; }
          else { tolerance = .0030; }

          this.maxMajor = (this.numTeeth + 1) / this.diametralPitch;
          this.minMajor = this.maxMajor - tolerance;
          break;

        case FIT_TYPE.MAJOR:
          this.maxMajor = (this.numTeeth + 1) / this.diametralPitch - 0.0001;
          this.minMajor = this.maxMajor - (3 + 2 * this.pitchDiameter) * 0.0001;
          break;

        default:
          console.log("Invalid fit type");
          break;

      } // switch
    }
    else // INTERNAL
    {
      switch (this.fitType)
      {
        case FIT_TYPE.FLAT:
          this.maxMajor = (this.numTeeth + 1.35) / this.diametralPitch + 0.004 ;
          this.minMajor = (this.numTeeth + 1.35) / this.diametralPitch;
          break;

        case FIT_TYPE.MAJOR:
          this.maxMajor = (this.numTeeth + 1) / this.diametralPitch + (10 + 3 * this.pitchDiameter) * 0.0001;
          this.minMajor = (this.numTeeth + 1) / this.diametralPitch;
          break;

        case FIT_TYPE.FILLET:
          switch (this.specPressureAngle)
          {
            case 30:
                this.maxMajor = (this.numTeeth + 1.9) / this.diametralPitch + this.deltaDre;
                this.minMajor = (this.numTeeth + 1.8) / this.diametralPitch;
              break;

            case 37.5:
                this.maxMajor = (this.numTeeth + 1.7) / this.diametralPitch + this.deltaDre;
                this.minMajor = (this.numTeeth + 1.6) / this.diametralPitch;
              break;

            case 45:
                this.maxMajor = (this.numTeeth + 1.5) / this.diametralPitch + this.deltaDre;
                this.minMajor = (this.numTeeth + 1.4) / this.diametralPitch;
              break;

            default:
              console.log("Could not find major diameter.");        
              break;
          }
          break;

        default:
          console.log("Invalid fit type");
          break;

      } // switch
    } // if-else
  } // calcMajor


  /*

    calcMinor()

    2.22 Minor Diameter = Dre, Di : The diameter of the major circle.

    Minor Circle : The circle formed by the inner- most surface of the spline. It is the root circle of the
    external spline or the insice circle (tooth tip circle) of the internal spline.    

  */
  calcMinor()
  {
    if (SPLINE_TYPE.INTERNAL === this.splineType)
    {

      /*

      In section 25.2 "Minor Diameter of Internal Spline". The minor diameter for all types of internal splines
      is calculated by adding twice the form clearance from Table 2 to the form diameter as calculated in paragraph 25.1.

      This is a bit confusing as 25.1 refers to the FORM diameter of EXTERNAL spline calculations but if you keep reading, 25.1.1 is
      refering to an flat root spline and this is the calculation that they are using to calculate the internal minor diameter.

      25.1.1 Reads: For the flat root type, the form diameter cannot be less than the value obtained by calculation from the
      formula:

        minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - (0.016 * this.diametralPitch) - 4.5, 2)) / (2 * this.diametralPitch);

      which is applied where it becomes larger than the calculated value obtained from paragraph 25.1, and until itbecomes smaller
      than the calculated value obtained by the formula in Table 105.

      25.1.2 Reads: For the fillet root type, the form diameter cannot be less than the value obtained by calculation from the:
      formula:

        minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - 5.359, 2)) / (2 * this.diametralPitch); 

      */

      // Calculate the value for Section 25.1.1
      var minFormDiameter = this.formDiameter;
      if ((FIT_TYPE.FLAT === this.fitType) || (FIT_TYPE.MAJOR === this.fitType))
      {
        minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - (0.016 * this.diametralPitch) - 4.5, 2)) / (2 * this.diametralPitch);
      }
      // For the  fillet type, the form diameter cannont be less than the value obtained by the following calculation
      else if (FIT_TYPE.FILLET === this.fitType) {
        minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - 5.359, 2)) / (2 * this.diametralPitch); 
      }
      var minorSection25 = minFormDiameter + 2 * this.formClearance;

      // Calculate the value of Table 105 to see if the previous value is less than this. If it is, we need to use the value from 
      // Table 105. (aka. this next calculation)
      var minorTable105 = 0;
      switch (this.specPressureAngle)
      {
        case 30:
          minorTable105 = (this.numTeeth - 1.0) / this.diametralPitch;
          break;

        case 37.5:
          minorTable105 = (this.numTeeth - 0.8) / this.diametralPitch ;
          break;

        case 45:
          minorTable105 = (this.numTeeth - 0.6) / this.diametralPitch;
          break;

        default:
          console.log("Could not find minor diameter.");        
          break;
      } // switch

      // Take the larger of the two calculations for the minumum minor.
      this.minMinor = Math.max(minorTable105, minorSection25);

      // Calculate the minor based on adding the tolerance from
      // Table 107 "MAJOR AND MINOR DIAMETER TOURANCES" to the minMinor.
      var tolerance = 0;
      if (this.diametralPitch <= 2.5) { tolerance = .0200; }
      else if (this.diametralPitch <= 3) { tolerance = .0150; }
      else if (this.diametralPitch <= 4) { tolerance = .0100; }
      else if (this.diametralPitch <= 5) { tolerance = .0080; }
      else if (this.diametralPitch <= 24) { tolerance = .0050; }
      else if (this.diametralPitch <= 48) { tolerance = .0030; }
      else { tolerance = .0020; } // Default tolerance for Flat and Fillet Root

      // Section 25.2 also says that you need to use the calculation from Section 25.1 that one would think only
      // relates to external splines. However, the comment "The form diameter for all types of external splines cannot be less
      // than the base diameter plus 0.010 in for 2.5/5 through 20/40 spline pitches. and not less than the hase diameter
      // plus 0.006 in for finer spline pitches is still valid. So, we need to check that here.
      if (this.diametralPitch <= 20) 
      {
        this.minMinor = Math.max(this.minMinor, this.baseDiameter + 0.010 + tolerance);
      }
      else
      {
        this.minMinor = Math.max(this.minMinor, this.baseDiameter + 0.006 + tolerance);
      }

      // Calcualte the max minor baed on the min minor and the tolerance
      this.maxMinor = this.minMinor + tolerance;            
    }
    else // EXTERNAL
    {
      if ((FIT_TYPE.FLAT === this.fitType) || (FIT_TYPE.MAJOR === this.fitType)) {
        this.maxMinor = (this.numTeeth - 1.35) / this.diametralPitch;
        this.minMinor = this.maxMinor - 0.004 - this.deltaDre;
      }
      else // FILLET
      {
        switch (this.specPressureAngle)
        {
          case 30:
            if (12 >= this.diametralPitch) {
              this.maxMinor = (this.numTeeth - 1.8) / this.diametralPitch;
              this.minMinor = (this.numTeeth - 1.9) / this.diametralPitch - this.deltaDre;
            }
            else {
              this.maxMinor = (this.numTeeth - 2) / this.diametralPitch;
              this.minMinor = (this.numTeeth - 2.1) / this.diametralPitch - this.deltaDre;
            }
            break;

          case 37.5:
            this.maxMinor = (this.numTeeth - 1.3) / this.diametralPitch;
            this.minMinor = (this.numTeeth - 1.4) / this.diametralPitch - this.deltaDre;
            break;

          case 45:
            this.maxMinor = (this.numTeeth - 1) / this.diametralPitch;
            this.minMinor = (this.numTeeth - 1.1) / this.diametralPitch - this.deltaDre;
            break;

          default:
            console.log("Could not calculate minor diameter.");        
            break;

        } // switch
      } // if-else
    } // if-else

  } // calMinor


  /*

    calcFormDiameter()

    2.24 Form Diameter = Dfe, Dfi : The diameter of the form circle.

    Form Circle : The circle which defines the deepest points of involute form control of the tooth profile.
    This circle along with the tooth tip circle (or start of chamfer circle) determines the limits of tooth profile
    requiring con· trol. It is located near the major circle on the internal spline and near the minor circle on the
    external spline.

  */
  calcFormDiameter()
  { 
    // INTERNAL
    if (SPLINE_TYPE.INTERNAL === this.splineType) {
        if (FIT_TYPE.MAJOR === this.fitType) {
          this.formDiameter = (this.numTeeth + 0.8) / this.diametralPitch - 0.004 + 2 * this.formClearance;
        }
        else {
          this.formDiameter = (this.numTeeth + 1) / this.diametralPitch + 2 * this.formClearance;          
        }
    }
    // EXTERNAL 
    else
    {
        switch (this.specPressureAngle)
        {
          case 37.5:
            this.formDiameter = (this.numTeeth - 0.8) / this.diametralPitch - 2 * this.formClearance;
            break;

          case 45: 
            this.formDiameter = (this.numTeeth - 0.6) / this.diametralPitch - 2 * this.formClearance;
            break;

          default: 
            this.formDiameter = (this.numTeeth - 1.0) / this.diametralPitch - 2 * this.formClearance;
            break;

        } // switch

        /*  
            ANSI Spec SECTION 25 Rules:

            The form diameter for external splines cannont be less than the base diameter + 0.010 for 2.5/5
            through 20/40 splines and not less than the base diameter + 0.006 for finer spline pitches.
        */
        if (this.diametralPitch <= 20) {
          this.formDiameter = Math.max(this.formDiameter, this.baseDiameter + 0.010);
        }
        else {
          this.formDiameter = Math.max(this.formDiameter, this.baseDiameter + 0.006);
        }

        // For the flat root type, the form diameter cannont be less than the value obtained by the following calculation
        var minFormDiameter = this.formDiameter;
        if ((FIT_TYPE.FLAT === this.fitType) || (FIT_TYPE.MAJOR === this.fitType)) {
          minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - (0.016 * this.diametralPitch) - 4.5, 2)) / (2 * this.diametralPitch);
        }
        // For the  fillet type, the form diameter cannont be less than the value obtained by the following calculation
        else if (FIT_TYPE.FILLET === this.fitType) {
          minFormDiameter = Math.sqrt(3 * Math.pow(this.numTeeth, 2) + Math.pow(this.numTeeth - 5.359, 2)) / (2 * this.diametralPitch); 
        }
        this.formDiameter = Math.max(this.formDiameter, minFormDiameter);
    }

  } // calcFormDiameter


  /*

    calcTolerance()

    Tolerance values are calculated from "TABLE 106-FORMULAS FOR MACHINING AND VARIATION ALLOWANCES FOR SPACE WIDTH
    AND TOOTH THICKNESS-CLASS 5" in the ANSI Spec.

    Note: Table 106 is based on Class 5 fit. So later we will need to adjust based on other classes when we use the tolerance.

  */
  calcTolerance()
  {
    if (this.diametralPitch <= 3)
    {
      this.m = 0.18 * this.numTeeth + 14;
      this.lambda = 0.35 * this.numTeeth + 20;
    }
    else if (this.diametralPitch <= 5)
    {
      this.m = 0.15 * this.numTeeth + 13;
      this.lambda = 0.23 * this.numTeeth + 18;
    }
    else if (this.diametralPitch <= 8)
    {
      this.m = 0.15 * this.numTeeth + 11;
      this.lambda = 0.20 * this.numTeeth + 15;
    }
    else if (this.diametralPitch <= 12)
    {
      this.m = 0.10 * this.numTeeth + 11;
      this.lambda = 0.17 * this.numTeeth + 14;
    }
    else if (this.diametralPitch <= 20)
    {
      this.m = 0.07 * this.numTeeth + 11;
      this.lambda = 0.12 * this.numTeeth + 13;
    }
    else if (this.diametralPitch <= 48)
    {
      this.m = 0.07 * this.numTeeth + 11;
      this.lambda = 0.12 * this.numTeeth + 11;
    }
    else if (this.diametralPitch <= 80)
    {
      this.m = 0.06 * this.numTeeth + 9;
      this.lambda = 0.10 * this.numTeeth + 10;
    }
    else {
      this.m = 0.05 * this.numTeeth + 9;
      this.lambda = 0.08 * this.numTeeth + 9;      
    }

    // Tolerances are in tenths. So we have to multiply by 0.0001
    this.m = this.m * 0.0001;
    this.lambda = this.lambda * 0.0001;

    this.deltaDre = 2 * (this.m + this.lambda) / Math.tan(util.toRadians(this.actualPressureAngle));

  } // calcTolerance


  /*
    
    calcSpaceAndToothWidth()

    2.27 Actual Space Width = s : The circular width on the pitch circle of any single space considering an infinitely
    thin increment of axial spline length.

    2.28 Effective Space Width = Sv :  The effective space width of an internal spline is equal to the circular
    tooth thickness on the pitch circle of an imaginary perfect external spline which would fit the internal spline
    without looseness or interference considering engagement of the entire axial length of the spline. The minimum
    effective space width of the internal spline is always basic, as shown in Table 1. Fit variations may be obtained
    by adjusting the tooth thickness of the external spline.

    2.29 Actual Tooth Thickness = t : The circular thickness on the pitch circle of any single tooth considering an
    infinitely thin increment of axial spline length.

    2.30 Effective Tooth Thickness = tv : The effective tooth thickness of an external spline is equal to the circular
    space width on the pitch circle of an imaginary perfect internal spline which would fit the external spline without
    looseness or interference. considering engage· ment of the entire axial length of the spline.    

  */
  calcSpaceAndToothWidth()
  {
    // Major Diameter Fit tooth width and space width are calculated based on note 10 and 11
    // in table 105. Otherwise, they are calculated based on the basic values from Table 2.

    // Turns out the the calculation for major diameter fit on internal splines is the same for flat
    // roots and fillet roots. So, we only need to apply note 10 and 11 to major fit for externals, only.
    var basic = 0;
    if ((FIT_TYPE.MAJOR === this.fitType) && (SPLINE_TYPE.EXTERNAL === this.splineType))
    {
      // Calculate maximum effective tooth thickness

      // Cv is based on paragraph 5.2 and table 107a
      var Cv = 0;
      if (this.diametralPitch <= 3)
      {
        Cv = 0.20 * this.numTeeth + 18;
      }
      else if (this.diametralPitch <= 5)
      {
        Cv = 0.15 * this.numTeeth + 16;
      }
      else if (this.diametralPitch <= 8)
      {
        Cv = 0.10 * this.numTeeth + 14;
      }
      else if (this.diametralPitch <= 12)
      {
        Cv = 0.07 * this.numTeeth + 14;
      }
      else 
      {
        Cv = 15;
      }

      // Cv is in tenths
      Cv = Cv * 0.0001;
      basic = Math.PI / (2 * this.diametralPitch) - Cv;

    }
    else
    {
      // Calculate basic space width and tooth thicknesses
      // Min Effective for Internal and Max Effective for external
      // are always the basic value.
      switch (this.specPressureAngle)
      {
        case 30:
          basic = Math.PI / (2 * this.diametralPitch); 
          break;

        case 37.5:
          basic = (0.5 * Math.PI + 0.1) / this.diametralPitch; 
          break;

        case 45:
          basic = (0.5 * Math.PI + 0.2) / this.diametralPitch; 
          break;

        default:
          console.log("Could not calculate space width.");        
          break;
      } // switch
    } // if MAJOR FIT

    // Now calculate the machining tolerance while compensating for class fit.
    // TABLE 106-FORMULAS FOR MACHINING AND VARIATION ALLOWANCES FOR SPACE WIDTH AND TOOTH THICKNESS for CLASS 5
    var machineTolerance = 0;
    var lambdaTolerance = 0;
    switch (this.classFit) 
    {
      // Class 4 = 0.71 X Tabulated value
      case CLASS_FIT.CLASS_4:
        machineTolerance = this.m * 0.71;
        lambdaTolerance = this.lambda * 0.71;
        break;

      // Class 6 = 1.40 X Tabulated value
      case CLASS_FIT.CLASS_6:
        machineTolerance = this.m * 1.40;
        lambdaTolerance = this.lambda * 1.4;
        break;

      // Class 7 = 2.00 X Tabulated value
      case CLASS_FIT.CLASS_7:
        machineTolerance = this.m * 2.0;
        lambdaTolerance = this.lambda * 2.0;
        break;

      // Class 5 = As tabulaled in table 106
      // Default of the spec assumes Class 5
      default: 
        machineTolerance = this.m; 
        lambdaTolerance = this.lambda;
        break;
    }

    // Calculate the alternte effective and actuals by applying tolerances.
    if (SPLINE_TYPE.INTERNAL === this.splineType)
    {
      this.minEffective = basic;
      this.maxEffective = this.minEffective + machineTolerance;

      this.minActual = this.minEffective + lambdaTolerance;
      this.maxActual = this.maxEffective + lambdaTolerance;
    }
    else // EXTERNAL
    {
      this.maxEffective = basic;
      this.minEffective = this.maxEffective - machineTolerance;

      this.maxActual = this.maxEffective - lambdaTolerance;
      this.minActual = this.minEffective - lambdaTolerance;
      this.width = (this.maxActual + this.minActual) / 2; // The defaul space width / tooth thickness is the average of the actual.
    } 

  } // calcSpaceAndToothWidth


  /*
    calcPins()

    Calculate the ideal pin size.

  */
  calcPins(pins)
  {
    // Calculate the size of the pin to use
    if ((45 === this.specPressureAngle) || (SPLINE_TYPE.EXTERNAL === this.splineType))
    {
        this.pinDiameter = 1.9200 / this.diametralPitch;
    }
    else 
    {
        this.pinDiameter = 1.7280 / this.diametralPitch;
    }

    // Now calculate the measure over each the pin we just created
    var measurements = involutePinMeasurements(this.numTeeth, this.pitchDiameter, this.baseDiameter, this.actualPressureAngle, this.splineType, this.width, this.pinDiameter);

    this.pinMeasurement = measurements.pinMeasurement;
    this.pinCenterRadius = measurements.pinCenterRadius;
    
  } // calcPins
  

  // Return the servolute of the specified angle.
  servolute(angle)
  {
    var radianAngle = util.toRadians(angle);
    return (1 / Math.cos(radianAngle)) - this.involute(angle);
  }

  involute (angle)
  {
    var radianAngle = util.toRadians(angle);
    return (Math.tan(radianAngle) - radianAngle);
  }

}; // SplineValues


/*

  Sevolute:

  The sevolute is a new function for use in involute gear calculations. The name is a
  contraction of the words Secant and inVOLUTE. The values were obtained from the general
  equation Sev x = Sec x - Tan x + Arc x (where x is the measure of an angle). The chief
  function of the sevolute is to simplify the problem of calculating 'full-radius' fillets
  or tips.  See also: http://gearsolutions.com/features/calculating-the-inverse-of-an-involute/

*/
