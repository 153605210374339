import React from 'react';
import swal from 'sweetalert';
import ValuesBase from './values-base';
import InvoluteScriptGenerator from "./script-involute"

import OverridableInput from './overridable-input';
import * as util from './utilities';
import './css/values.css';

export const SPLINE_TYPE = { 
    INTERNAL	: "Internal",
    EXTERNAL 	: "External" 
  };

export default class CustomValues extends ValuesBase
{
	constructor(props)
	{		
		super(props);

		// Setup default values for the first time the values are loaded.
		this.state = {
			numTeeth: 20,
			diametralPitch: 16,
			pressureAngle: 30,
			major: 1.3015,
			minor: 1.1588,
			formDiameter: 1.1835,
			pitchDiameter: 1.250,
			effective: .0982,
			actual: .0966,
			splineType: SPLINE_TYPE.EXTERNAL,
			pinDiameter: .1200,
			isOverridden: false
		};

		// Calculate the initial values.
		let pinMeasures = this.calcPinMeasures(this.pinDiameter);
		Object.assign(this.state, pinMeasures);
	
	} // constructor


	updateCalculations()
	{
        
    } // updateCalculations
    

	// Check to see if the major diameter is so large that the involute points will intersect before reaching the major.
	checkMajor(major, baseDiameter, pitchDiameter, width)
	{    
    	// Calculate the angle of the involute point where it intersects the pitch circle.
		// Half of this value is the center where two involutes will intersect when draw both halves.
		var pt = util.involuteCircleIntersect(baseDiameter, pitchDiameter);
		var circularSpaceAngle = util.toDegrees(width / (pitchDiameter / 2));
		var midpointAngle = circularSpaceAngle / 2 + pt.t;

		// When the t value of the major diameter intersection is greater than the midpoint angle, then 
		// the major diameter is too large and the involute will cross each other.
		var involuteIntersectPtMajor = util.involuteCircleIntersect(baseDiameter, major);
		if (involuteIntersectPtMajor.t > midpointAngle)
		{
			// ERROR: The major diameter is larger than tne intersection of the two involutes for each side of the teeth.
			return false;
		}
		return true;

	} // checkMajor


	// calculateValues is called by the base class when it needs new spline values from the spec.
	// The base class will also update the state based on any new spline values
	// that have changed and notify the parent of the changes. All we have to do here is
	// call the appropriate engine to do the calulation.
	calculateValues()
	{		
		
	} // calculateValues


	isOverridden(state)
	{
		return (state.majorOverridden ||
			state.minorOverridden ||
			state.widthOverridden ||
			state.pinOverridden);
	}

	
	resetOverridden()
	{
		return {
			majorOverridden: false,
			minorOverridden: false,
			widthOverridden: false,
			pinOverridden: false
		};
	}


	processValueUpdate(id, newValue)
	{
		var oldValue = this.state[id];
		
		// If the new value is the same as the old value, then there is no work to do.
		if (String(newValue) === String(oldValue)) return;

		let updateState = {};
		let askingUser = false;

		switch (id)
		{
			case "width":
				updateState.widthOverridden = true;
				this.state.width = newValue;
				let pinMeasures = this.calcPinMeasures(this.state.pinDiameter);
				updateState = Object.assign(updateState, pinMeasures);
				break;

			case "pinDiameter":
				updateState.pinOverridden = true;
				newValue = Number(newValue);
				this.state.pinDiameter = newValue;
				pinMeasures = this.calcPinMeasures(this.state.pinDiameter);
				updateState = Object.assign(updateState, pinMeasures);
				break;


			case "major":
				let isValid = this.checkMajor(newValue, this.state.baseDiameter, this.state.pitchDiameter, this.state.width);
				if (!isValid) {
					askingUser = true;
					swal({
						title: "Major diamter too large",
						text: "The involute sides of each tooth will intersect before reaching the major diameter. Please reduce the major diameter size.",
						icon: "info"
					  })
					.then(() => {
							updateState[id] = oldValue;
							this.setState(updateState);
						}
					);				
				}
				updateState.majorOverridden = true;
				break;

			case "numTeeth":
			case "diametralPitch":
			case "pressureAngle":
			case "splineType":
			case "rootType":
			case "classFit":
				if (this.isOverridden(this.state)) {
					// Check to see if the user wants to continue
					askingUser = true;
					swal({
						title: "Are you sure?",
						text: "Some values have been overridden. Changing this will reset the calculations and remove the overridden changes. Are you sure you want to continue?", 
						icon: "warning",
						buttons: ["Cancel", "Continue"],
						dangerMode: true,
					  })
					  .then(shouldContinue => {
							if (shouldContinue) {
								// Swtiching back to calculated values
								updateState[id] = newValue;
								Object.assign(updateState, this.resetOverridden());
								this.setState(updateState, this.updateCalculations);
							}
							else {
								// Cancel selected
								updateState[id] = oldValue;
								this.setState(updateState);
							}
					  	}
					);				
				}
				break;
			case "minor":
				updateState.minorOverridden = true;
				break;
		}

		if (!askingUser) {
			// Check to see if changing this value will make is so we can't calculate
			this.calculateValues();
			
			updateState[id] = newValue;
			let isOverridden = this.isOverridden(updateState);
			this.setState(updateState, isOverridden ? this.notifyUpdate : this.updateCalculations);
		}

/*
		catch(e)
		{
			window.alert(e.toString());

			// Reset the value and display an error.
			this.state[id] = oldValue;		
			let updateState = [];
			updateState[id] = oldValue;
			this.setState(updateState);
			return false;
        }		
*/	
	} // processValueUpdate


	calcPinMeasures(pinDiameter)
	{	
		let pinMeasurement = 0;			
		return {
			pinDiameter: 		Number(pinDiameter),
			pinMeasurement: 	Number(pinMeasurement)
		};

	} // calcPinMeasures


	// createScript knows which script generator to use based on the type of values and 
	// calculation engine that this object represents. So, when we need a scipt generated
	// we just as the object to create me a script generator of the appropriate type and 
	// generate the script.
	createScript(renderer)
	{
		let splineValues = Object.assign(this.state.splineValues, this.state);			
		let scriptGeneator = new InvoluteScriptGenerator(splineValues, renderer);	
		return scriptGeneator.createScript();

	} // createScript
	
	
	render()
	{
	    return (
				<div>					
					<div>
						<h4>Specification Settings:</h4>
					</div>					
					
					<div className="input-values">

						<label htmlFor="numTeeth">Teeth</label>
						<OverridableInput precision="0" id="numTeeth" value={this.state.numTeeth} onBlur={this.handleBlur} />

						<label htmlFor="diametralPitch">Diametral Pitch</label>
						<OverridableInput precision="1" id="module"  value={this.state.diametralPitch}  onBlur={this.handleBlur} />

						<label htmlFor="pressureAngle">Pressure Angle</label>
						<OverridableInput precision="1" id="pressureAngle"  value={this.state.pressureAngle}  onBlur={this.handleBlur} />
						
						<label htmlFor="splineType">Type</label>
						<select id="splineType" value={this.state.splineType} onChange={this.handleSelectChange} >
							{util.createSelectItems(SPLINE_TYPE)}
						</select>
					
						<label htmlFor="major">Major</label>
						<OverridableInput precision="2" overridden={this.state.majorOverridden} id="major" value={this.state.major} onBlur={this.handleBlur} />

						<label htmlFor="minor">Minor</label>
						<OverridableInput precision="2" overridden={this.state.minorOverridden} id="minor" value={this.state.minor} onBlur={this.handleBlur} />

						<label htmlFor="width">Tooth Thickness</label>
						<OverridableInput precision="3" overridden={this.state.widthOverridden} id="width" value={this.state.width} onBlur={this.handleBlur} />

						<label htmlFor="formDiameter">Form Diameter</label>
						<OverridableInput disabled id="formDiameter" value={this.state.formDiameter} />

						<label htmlFor="pitchDiameter">Pitch Diameter</label>
						<OverridableInput disabled id="pitchDiameter" value={this.state.pitchDiameter} />

						<label htmlFor="baseDiameter">Base Diameter</label>
						<OverridableInput disabled id="baseDiameter" value={this.state.baseDiameter} />

						<label htmlFor="effective">Effective</label>
						<OverridableInput disabled id="effective" value={this.state.effective} />

						<label htmlFor="actual">Actual</label>
						<OverridableInput disabled id="minActual" value={this.state.minActual} />
						
					</div>

					<div>
						<h4>Pin Dimensions:</h4>
					</div>

					<div className="input-values">
							<label htmlFor="pinDiameter">Pin Diameter</label>
							<OverridableInput overridden={this.state.pinOverridden} id="pinDiameter" value={this.state.pinDiameter} onBlur={this.handleBlur}/>

							<label htmlFor="pinMeasurement">Measurement</label>
							<OverridableInput precision="3" disabled id="pinMeasurement" value={this.state.pinMeasurement}/>

					</div>

				</div>
		  );
		  
	} // render

} // CustomValues
