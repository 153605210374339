import BaseScriptGenerator from './script-base';
import SerrationProfile, {SIDE} from './profile-serration';
import * as j500 from './calc-j500';


class Tooth
{
	constructor(toothAngle, includedAngle, majorDiameter, minorDiameter, od, rd)
	{
		// Add 90 degrees to the tooth angle so that the top center one is the first tooth and is always centered.
		let angle = toothAngle + 90; // Angle of this tooth in the overall drawing.

		// Calculate the angle of the point the side of the tooth touches the major and minor diameters.

        // Calculate the tooth's left hand profile points
		this.leftProfile = new SerrationProfile(SIDE.LEFT, angle, includedAngle, majorDiameter, minorDiameter, od, rd);

		// Calculate the tooths right hand profile points
		this.rightProfile = new SerrationProfile(SIDE.RIGHT, angle, -includedAngle, majorDiameter, minorDiameter, od, rd);
	}


	draw(renderer, color)
	{
		var script = [];

		// Draw the right side of the involute
		script = script.concat(this.leftProfile.draw(renderer, color));
		script = script.concat(this.rightProfile.draw(renderer, color));
		return script;

	} // draw

} // Tooth


export default class SerrationScriptGenerator extends BaseScriptGenerator
{
	constructor(splineValues, renderer)
	{
		super(splineValues, renderer);
		this.isInternal = j500.SPLINE_TYPE.HOLE === this.splineType;

	} // constructor


	createTooth(angle)
	{
		return new Tooth(
			angle,
			this.splineValues.includedAngle,
			this.splineValues.major,
			this.splineValues.minor,
			this.splineValues.od,
			this.splineValues.rd );
	}

}
