import React from 'react';

export default class ValuesBase extends React.Component 
{
	constructor(props)
	{
		super(props);

		// This binding is necessary to make `this` work in the callbacks
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.notifyUpdate = this.notifyUpdate.bind(this);

		// Register this object as being the active script generator.
		if (props.registerScriptGenerator) {
			props.registerScriptGenerator(this);
		}
	}

	
	// calculateValues must be overridden.
	// calculateValues is called by the base class when it needs new spline values.
	// The base class will also update the state based on any new spline values
	// that have changed and notify the parent of the changes. All we have to do here is
	// call the appropriate engine to do the calulation.
    calculateValues()
    {
        console.log("SpecValues error: calculateValues must be implemented by the derived class.");
	}
	

	// createScript must be overridden.
	// createScript knows which script generator to use based on the type of values and 
	// calculation engine that this object represents. So, when we need a scipt generated
	// we just as the object to create me a script generator of the appropriate type and 
	// generate the script.
	createScript(renderer)
    {
        console.log("SpecValues error: createScript must be implemented by the derived class.");
	}

	
	updateCalculations()
	{
        console.log("SpecValues error: updateCalculations must be implemented by the derived class.");
        
    } // updateCalculations


	componentDidMount()
	{
		// The first time we mount, let the parent know that we have done all our calculations and 
		// it can update the drawing, etc.
		this.notifyUpdate();
	}


	notifyUpdate()
	{
		if (this.props.onChange)
		{
			// Before notifying the parent of the updated spline values, we need to write the value of the 
			// non-calculated controls to the splineValue structure since that is what is used to render.
			let splineValues = Object.assign(this.state.splineValues, this.state);			
			this.props.onChange(splineValues);
		}
	}
    

	// The base class handleSelectChange just sets the state of the new value of the selection and recalculates the spline values.
	// Override this method if different behavior is desired.
	handleChange(event)
	{
		let id =  event.target.id;
		let newValue = event.target.value;

		let updateState = {};
		updateState[id] = newValue;
		this.setState(updateState);

		this.processValueUpdate(id, newValue);

	} // handleChange
	

	// The base class handleBlur just sets the state of the new value and recalculates the spline values.
	// Override this method if different behavior is desired.
    handleBlur(id, newValue)
	{
		this.processValueUpdate(id, newValue);

	} // handleBlur

} // SpecValues