import React from 'react';
import BaseShapeRenderer from "./shape-base"

// Increase all of the values by a SCALE_FACTOR since they draw bigger in SVG
export const SCALE_FACTOR = 100;

export class PreviewRenderer extends BaseShapeRenderer
{
	constructor()
	{
		super();
		this.strokeWidth = 1;
	}

	COLOR () {
		return {
			RED:	"red",
			YELLOW: "cornflowerblue",
			BLACK: 	"black",
			GREEN: 	"seagreen"
		};
	}


	setStrokeWidth(strokeWidth)
	{
		this.strokeWidth = strokeWidth;
	}


	curve(profile, color)
	{
		// Profiles have node points for the path. For SVG, these points need to be b-splines.
		// As such, we need to add the bezier points to the profile array.
		this.insertBezierPoints(profile);

		var script = [];
		var strokeWidth = this.strokeWidth;
		script = script.concat(
			profile.map(function(splinePath) {
       			return <path d={splinePath.d} strokeWidth={strokeWidth} stroke={color} stroke-linecap="round" fill="none"/>;
    		})
		);
		return script;
	}

	circle (x, y, radius, color)
	{
		// Increase all of the values by a SCALE_FACTOR since they draw bigger in SVG
		x = x * SCALE_FACTOR;
		y = y * SCALE_FACTOR;
		radius = radius * SCALE_FACTOR;
		
		return ([
			<circle cx={x} cy={y} r={radius} stroke={color} strokeWidth={this.strokeWidth} fill="none" />
		]);
	}

	arc(startX, startY, centerX, centerY, endX, endY, color)
	{
		// Increase all of the values by a SCALE_FACTOR since they draw bigger in SVG
		startX = startX * SCALE_FACTOR;
		startY = startY * SCALE_FACTOR;
		centerX = centerX * SCALE_FACTOR;
		centerY = centerY * SCALE_FACTOR;
		endX = endX * SCALE_FACTOR;
		endY = endY * SCALE_FACTOR;

		var d = "M " + startX + " " + startY + " A " + centerX + " " + centerY + " 0 0 0 " + endX + " " + endY;
		return ([
			<path d={d} strokeWidth={this.strokeWidth} stroke-linecap="round" stroke={color} fill="none"/>
		]);

	}

	line(startX, startY, endX, endY, color)
	{
		// Increase all of the values by a SCALE_FACTOR since they draw bigger in SVG
		startX = startX * SCALE_FACTOR;
		startY = startY * SCALE_FACTOR;
		endX = endX * SCALE_FACTOR;
		endY = endY * SCALE_FACTOR;
		return ([<line x1={startX} y1={startY} x2={endX} y2={endY} stroke={color} strokeWidth={this.strokeWidth} />]);
	}

	/*
		Update the splines with the computed spline control points
	*/
	insertBezierPoints(S)
	{	
		// grab (x,y) coordinates of the control points*/
		var x = [];
		var y = [];
		for (let i = 0; i < S.length; i++)
		{
			// use parseInt to convert string to int
			x[i]=S[i].x;
			y[i]=S[i].y;
		}
		
		// computes control points p1 and p2 for x and y direction
		var px = this.computeControlPoints(x);
		var py = this.computeControlPoints(y);
		
		// updates path points
		for (let i = 0; i < S.length ; i++)
		{
			S[i].d = this.path(x[i], y[i], px.p1[i], py.p1[i], px.p2[i], py.p2[i], x[i+1], y[i+1]);
		}

	} // insertBezierPoints


	/*
		creates formated path string for SVG cubic path element
	*/
	path(x1, y1, px1, py1, px2, py2, x2, y2)
	{
		// Increase all of the values by a SCALE_FACTOR since they draw bigger in SVG
		x1 = x1 * SCALE_FACTOR;
		y1 = y1 * SCALE_FACTOR;
		px1 = px1 * SCALE_FACTOR;
		py1 = py1 * SCALE_FACTOR;
		px2 = px2 * SCALE_FACTOR;
		py2 = py2 * SCALE_FACTOR;
		x2 = x2 * SCALE_FACTOR;
		y2 = y2 * SCALE_FACTOR;

		if (isNaN(px1) || isNaN(px2) || isNaN(py1) || isNaN(py2))
		{
			return "M "+x1+" "+y1;
		}
		else
		{
			return "M "+x1+" "+y1+" C "+px1+" "+py1+" "+px2+" "+py2+" "+x2+" "+y2;
		}
	}


	/*
		computes control points given knots K, this is the brain of the operation
	*/
	computeControlPoints(K)
	{
		var p1 = [];
		var p2 = [];
		var n = K.length-1;
		
		// rhs vector
		var a = [];
		var b = [];
		var c = [];
		var r = [];
		
		// left most segment
		a[0] = 0;
		b[0] = 2;
		c[0] = 1;
		r[0] = K[0]+2*K[1];
		
		// internal segments
		for (let i = 1; i < n - 1; i++)
		{
			a[i] = 1;
			b[i] = 4;
			c[i] = 1;
			r[i] = 4 * K[i] + 2 * K[i+1];
		}
				
		// right segment
		a[n-1] = 2;
		b[n-1] = 7;
		c[n-1] = 0;
		r[n-1] = 8*K[n-1]+K[n];
		
		// solves Ax=b with the Thomas algorithm (from Wikipedia)
		for (let i = 1; i < n; i++)
		{
			var m = a[i]/b[i-1];
			b[i] = b[i] - m * c[i - 1];
			r[i] = r[i] - m*r[i-1];
		}
	 
		p1[n-1] = r[n-1]/b[n-1];
		for (let i = n - 2; i >= 0; --i)
			p1[i] = (r[i] - c[i] * p1[i+1]) / b[i];
			
		// we have p1, now compute p2
		for (let i=0;i<n-1;i++)
			p2[i]=2*K[i+1]-p1[i+1];
		
		p2[n-1]=0.5*(K[n]+p1[n-1]);
		
		return {p1:p1, p2:p2};

	} //computeControlPoints
	
} // SVGShapeRenderer