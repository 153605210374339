import BaseShapeRenderer from "./shape-base"
import * as util from "./utilities"

export default class DxfShapeRenderer extends BaseShapeRenderer
{
	COLOR () {
		return {
			BLACK:	"0",
			RED:	"1",
			YELLOW: "2",
			GREEN: 	"3",
			CYAN: 	"4",
			BLUE:	"5"
		};
	}

	curve(profile)
	{
		// For DXF files, we just write the profile of the tooth out in little line segments.
		// This is because it was taking forever to figure out how the newer DXF spec that
		// uses splines works. The newer version of the spec is required because older versions of
		// Autocad did not support SPLINE. The problem is that newer version of the spec have a lot of 
		// mandatory sections and values. The spec is horribly written and not worth the time to figure out.
		// So, we just write out the 20 little line segemnts of the profile and the tooth looks pretty good.

		let script = [
			"0",
			"POLYLINE",
			"5",
			"202",
			"8",
			"0",
			"66",
			"1",
			"10",
			"0.0",
			"20",
			"0.0",
			"30",
			"0.0"
		];

		// Write the fit points
		for (var i = 0, len = profile.length; i < len; i++)
		{
			script = script.concat([
				"0",
				"VERTEX",
				"5",
				"2A4",
				"8",
				"0",
				"10",
				profile[i].x,
				"20",
				profile[i].y,
				"30",
				"0.0"
			]);
		}

		script = script.concat([
				"0",
				"SEQEND",
				"5",
				"2A7",
				"8",
				"0"
		]);

		return script;

	}

	circle (x, y, radius, color, layerName)
	{
		if (layerName === undefined)
		{
			layerName = "Circles"
		}

		return ([
			"0", 		// Entity type follows
			"CIRCLE", 	// Entity type
			"8", 		// Layer name is placed on is next
			layerName,		// Layer name
			"10",		// First X value - Center of CIRCLE
			x,
			"20",		// First Y value - Center of CIRCLE
			y,
			"30",
			"0.0",
			"40",		// Radius of CIRCLE
			radius,
			"62",		// Color entity
			color
		]);
	}

	arc(startX, startY, centerX, centerY, endX, endY)
	{	
		let radius = Math.sqrt(Math.pow(startX, 2) + Math.pow(startY,2));

		let startAngle = util.toDegrees(Math.atan2(startY, startX));
		let endAngle = util.toDegrees(Math.atan2(endY, endX));

		return (
		[
			"0", 	// Entity type follows
			"ARC", // Entity type
			"8", 	// Layer name is placed on is next
			"0",	// Layer name
			"10",	// First X value - Center of ARC
			centerX,
			"20",	// First Y value - Center of ARC
			centerY,
			"40",	// Radius of ARC
			radius,
			"50",	// Start angle of ARC
			startAngle,
			"51",	// End angle of ARC
			endAngle,
		]);
	}

	layer(name, color)
	{
		return [];
/*
		return (
		[
			"-LAYER MAKE " + name, 
			"",
			"-LAYER COLOR " + color, 
			"",
			"",
		]);
*/
	}

	line(startX, startY, endX, endY, color)
	{
		return (
			[
				"0", 	// Entity type follows
				"LINE", // Entity type
				"8", 	// Layer name is placed on is next
			  	"0",	// Layer name
			   	"10",	// First X value - Start of line
			  	startX,
			  	"20",	// First Y value - Start of line
				startY,
				"11",	// Second X value - End of line
				endX,
				"21",	// Second Y value - End of line
				endY
			]);
	}

	text(x, y, text, height)
	{
		return (
			[
				"0", 	// Entity type follows
				"TEXT",	// Entity type	
				"8",
				"Spline Data",	// Layer to place text onto.
				"39", 	// Thickness
				"20",	
				"10",	// X value - lower left corner of text
				x,
				"20",	// Y value - lower left corner of text
				y,
				"40",	// Text height
				height,
				"41",	// Relative X scale factor-width
				"1",
				"1",	// Text to display
				text
			]);
	}

	header()
	{
		return (
			[
				"0",
				"SECTION",
				"2",
				"HEADER",
				"0",
				"ENDSEC",
				"0",
				"SECTION",
  				"2",
				"ENTITIES",
			]
		);
	}

	footer()
	{
		return (
			[
				"0",
				"ENDSEC",
  				"0",
				"EOF",
				"",
			]
		);
	}	
	
}
