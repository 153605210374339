import BaseProfile from './profile-base';
import * as util from './utilities';

export {SIDE} from './profile-base';

export default class SerrationProfile extends BaseProfile
{
	constructor(side, toothAngle, includedAngle, majorDiameter, minorDiameter, od, rd)
	{
		super();

		this.profile = [];

		var toothAngleRadians = util.toRadians(toothAngle);
		
		// Create a segment of the side of the tooth so we can calculate
		// where it intersects the max and min major diameters.
		// Note: we are creating only a line segment. This segment starts at the 
		// intersection point of the tooth sides but does NOT go all the way to one 
		// of the circles. It is only used to get two points on the line so we can
		// calculate where the line intersects one of the circles. 
		let p1 = new util.Point(
			(od / 2) * Math.sin(util.toRadians(90)),
			(od / 2) * Math.cos(util.toRadians(90)) 
		); 

		let i = (od - minorDiameter) / 2;
		let n = i * Math.tan(util.toRadians(includedAngle / 2));	
		let p2 = new util.Point(
			p1.x - i,
			p1.y + n
		)

		let intersectPt = util.lineCircleIntersect(p1, p2, majorDiameter / 2);
		let tipX = intersectPt.x * Math.cos(toothAngleRadians) - intersectPt.y * Math.sin(toothAngleRadians);
		let tipY = intersectPt.x * Math.sin(toothAngleRadians) + intersectPt.y * Math.cos(toothAngleRadians);        
		let tipPt = new util.Point(tipX, tipY, 0);

		intersectPt = util.lineCircleIntersect(p1, p2, minorDiameter / 2);
		let rootX = intersectPt.x * Math.cos(toothAngleRadians) - intersectPt.y * Math.sin(toothAngleRadians);
		let rootY = intersectPt.x * Math.sin(toothAngleRadians) + intersectPt.y * Math.cos(toothAngleRadians);        
		let rootPt = new util.Point(rootX, rootY, 0);

		this.profile.push(rootPt);
		this.profile.push(tipPt);

	} // constructor


	draw(renderer, color)
	{
		let start = this.start;
		let end = this.end;
		return renderer.line(start.x, start.y, end.x, end.y, color);
	} // draw

} // Profile
