import BaseProfile from './profile-base';
import * as util from './utilities';

export {SIDE} from './profile-base';

const NUM_PROFILE_POINTS = 20;

export default class InvoluteProfile extends BaseProfile
{
	constructor(side, toothAngle, baseDiameter, majorDiameter, minorDiameter)
	{
		super();

		var toothAngleRadians = util.toRadians(toothAngle);

		// Make sure we have a tooth profile to even draw.
		if (majorDiameter < minorDiameter) { majorDiameter = minorDiameter; }

		// Calculate where the involute intersects for the major and minor diameters. Since we don't 
		// draw the involute beyond those points, we want to make sure we have a point at each.
		var involuteIntersectPtMajor = util.involuteCircleIntersect(baseDiameter, majorDiameter);
		var involuteIntersectPtMinor = util.involuteCircleIntersect(baseDiameter, minorDiameter);		

		// Calculate and store the involute points for this profile. 
		// We don't need to draw points on the involute if there are greater than the major or less than the minor.
		// So we start at the angle the involute intersects the minor and create points to the angle that
		// the involute intersects the major.
		var increment = (involuteIntersectPtMajor.angle - involuteIntersectPtMinor.angle) / NUM_PROFILE_POINTS;
		let angle = involuteIntersectPtMinor.angle;
		for (var i = 0; i <= NUM_PROFILE_POINTS; i++)
		{
			var angleRadians = util.toRadians(angle);
			var x = ((baseDiameter) / 2 * (Math.cos(angleRadians) + angleRadians * Math.sin(angleRadians)));
			var y = ((baseDiameter / 2) * (Math.sin(angleRadians) - angleRadians * Math.cos(angleRadians))) 
			
			y *= side; // flip the y based on what side we are creating the profile for.

			// Now rotate the invoute profile point by the incoming tooth angle.
			var xNew = x * Math.cos(toothAngleRadians) - y * Math.sin(toothAngleRadians);
			var yNew = x * Math.sin(toothAngleRadians) + y * Math.cos(toothAngleRadians);
			var involutePt = new util.Point( xNew, yNew, angle);

			this.profile.push(involutePt);
			angle += increment;
		}

	} // constructor

	draw(renderer, color)
	{
		return renderer.curve(this.profile, color);
	}

} // InvoluteProfile
