import BaseScriptGenerator from './script-base';
import InvoluteProfile, {SIDE} from './profile-involute';
import * as ansi from './calc-ansi-b92_1';
import * as util from './utilities';

/*
function maxInvolute(midpointAngle, baseDiameter)
{
	let midpointAngleRadians = util.toRadians(0);

	for (let angle = 0; angle < 360; angle++)
	{
		// Calculate the involute at that angle
		let baseRadius = baseDiameter / 2;
		var angleRadians = util.toRadians(angle);
		let x = baseRadius * (Math.cos(angleRadians) + angle * Math.sin(angleRadians));	// x of involute curve given angle t			
		let y = baseRadius * (Math.sin(angleRadians) - angle * Math.cos(angleRadians));	// y of involute curve given angle t			

		// Rotate the involute by the midpoint angle to see when the value crosses the axis.
		var xNew = x * Math.cos(midpointAngleRadians) - y * Math.sin(midpointAngleRadians);
		console.log(angle, x, y, xNew);
	}
}
*/

class Tooth
{
	constructor(toothAngle, baseDiameter, major, minor, pitchDiameter, width)
	{
		// Add 90 degrees to the tooth angle so that the top center one is the first tooth and is always centered.
		this.angle = toothAngle + 90; // Angle of this tooth in the overall drawing.

		this.baseDiameter = baseDiameter;
		this.pitchDiameter = pitchDiameter;
		this.width = width;

		// Calculate the angle of the involute point where it intersects the pitch circle.
		// Half of this value is the center where two involutes will intersect when draw both halves.
		var pt = util.involuteCircleIntersect(this.baseDiameter, this.pitchDiameter);
		var circularSpaceAngle = util.toDegrees(this.width / (this.pitchDiameter / 2));
		var midpointAngle = circularSpaceAngle / 2 + pt.t;

		// Calculate the tooth's left hand profile points
		this.leftProfile = new InvoluteProfile(
			SIDE.LEFT,
			this.angle + midpointAngle,
			baseDiameter,
			major,
			minor);

		// Calculate the tooths right hand profile points
		this.rightProfile = new InvoluteProfile(
			SIDE.RIGHT,
			this.angle - midpointAngle,
			baseDiameter,
			major,
			minor);
	}

	draw(renderer, color)
	{
		var script = [];

		// Draw the right side of the involute
		script = script.concat(this.leftProfile.draw(renderer, color));
		script = script.concat(this.rightProfile.draw(renderer, color));
		return script;

	} // draw

} // Tooth


export default class InvoluteScriptGenerator extends BaseScriptGenerator
{
	constructor(splineValues, renderer)
	{
		super(splineValues, renderer);
		this.isInternal = ansi.SPLINE_TYPE.INTERNAL === splineValues.splineType;

	} // constructor

	createScript()
	{    
    	// Calculate the angle of the involute point where it intersects the pitch circle.
		// Half of this value is the center where two involutes will intersect when draw both halves.
		var pt = util.involuteCircleIntersect(this.splineValues.baseDiameter, this.splineValues.pitchDiameter);
		var circularSpaceAngle = util.toDegrees(this.splineValues.width / (this.splineValues.pitchDiameter / 2));
		var midpointAngle = circularSpaceAngle / 2 + pt.t;

		// Check to see if the major diameter is so large that the involute points will intersect before reaching the major.
//		maxInvolute(midpointAngle, this.splineValues.baseDiameter);

		// When the value of the major diameter intersection is greater than the midpoint angle, then 
		// the major diameter is too large and the involute will cross each other.
		var involuteIntersectPtMajor = util.involuteCircleIntersect(this.splineValues.baseDiameter, this.splineValues.major);
		if (involuteIntersectPtMajor.t > midpointAngle)
		{
			// ERROR: The major diameter is larger than tne intersection of the two involutes for each side of the teeth.
			console.log(this.splineValues.major, "The major diameter is larger than tne intersection of the two involutes for each side of the teeth.");
		}

		// Everthing looks to be fine. Continue creating the script.
		return super.createScript();
	}

	createTooth(angle)
	{
		return new Tooth(
			angle,
			this.splineValues.baseDiameter,
			this.splineValues.major,
			this.splineValues.minor,
			this.splineValues.pitchDiameter,
			this.splineValues.width);
	}


	// Renders the data of the spline in text format on the drawing.
	renderText()
	{
		let LINES_OF_TEXT = 24;
		let lineHeight = this.splineValues.maxMajor / LINES_OF_TEXT; // Get all the lines to fit in the height of the drawing.
		let textHeight = lineHeight *.5; // Make the text 30% smaller than the height of each line.
		let x = (this.splineValues.maxMajor / 2) + (this.splineValues.maxMajor * .1); // The center is at 0. So we only need 1/2 the major.
		let y = lineHeight * LINES_OF_TEXT / 2;

		this.lineOut(x, y-=lineHeight, "Teeth: " + this.splineValues.numTeeth, textHeight);		
		this.lineOut(x, y-=lineHeight, "DP: " + this.splineValues.diametralPitch, textHeight)
		this.lineOut(x, y-=lineHeight, "PA: " + this.splineValues.specPressureAngle, textHeight);
		this.lineOut(x, y-=lineHeight, "Type: " + this.splineValues.splineType, textHeight);
		this.lineOut(x, y-=lineHeight, "Fit: " + this.splineValues.fitType, textHeight);
		this.lineOut(x, y-=lineHeight, "Class: " + this.splineValues.classFit, textHeight);
		this.lineOut(x, y-=lineHeight, "Major: " + Number.parseFloat(this.splineValues.major, textHeight).toFixed(4), textHeight);;
		this.lineOut(x, y-=lineHeight, "Minor: " + Number.parseFloat(this.splineValues.minor, textHeight).toFixed(4), textHeight);;
		this.lineOut(x, y-=lineHeight, "Tooth / Space Width: " + Number.parseFloat(this.splineValues.width).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Pin Diameter: " + this.splineValues.pinDiameter, textHeight);
		this.lineOut(x, y-=lineHeight, "Pin Measure: " + Number.parseFloat(this.splineValues.pinMeasurement).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Min Major: " + Number.parseFloat(this.splineValues.minMajor).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Max Major: " + Number.parseFloat(this.splineValues.maxMajor).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Min Minor: " + Number.parseFloat(this.splineValues.minMinor).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Max Minor: " + Number.parseFloat(this.splineValues.maxMinor).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Form Diameter: " + Number.parseFloat(this.splineValues.formDiameter).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Pitch Diameter: " + Number.parseFloat(this.splineValues.pitchDiameter).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Base Diameter: " + Number.parseFloat(this.splineValues.baseDiameter).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Tooth Height: " + Number.parseFloat(this.splineValues.toothHeight).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Min Effective: " + Number.parseFloat(this.splineValues.minEffective).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Max Effective: " + Number.parseFloat(this.splineValues.maxEffective).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Min Actual: " + Number.parseFloat(this.splineValues.minActual).toFixed(4), textHeight);
		this.lineOut(x, y-=lineHeight, "Max Effective: " + Number.parseFloat(this.splineValues.maxActual).toFixed(4), textHeight);

	}

	lineOut(x, y, text, height)
	{
		let newElements = this.renderer.text(x, y, text, height);
		this.script = newElements ? this.script.concat(newElements) : this.script;
	}
	
	
/*
	renderFilletRoot(toothNum)
	{
		var halfToothWidthAngle = 0;
		var filletCenterRadius = 0;
		if (this.isInternal)
		{
			halfToothWidthAngle = 0; // Don't rotate the fillet 1/2 a tooth for internal.
			filletCenterRadius = this.splineValues.major / 2 - this.splineValues.filletRadius;
		}
		else // EXTERNAL
		{
			halfToothWidthAngle = 360 / this.splineValues.numTeeth / 2;
			filletCenterRadius = this.splineValues.minor / 2 + this.splineValues.filletRadius;
		}

		var toothAngle = 360 / this.splineValues.numTeeth * toothNum + halfToothWidthAngle;
		var x = filletCenterRadius * Math.sin(util.toRadians(toothAngle));
		var y = filletCenterRadius * Math.cos(util.toRadians(toothAngle));

		var newElements = this.this.renderer.circle(x,y, this.splineValues.filletRadius, this.COLOR.BLACK);
		this.script = newElements ? this.script.concat(newElements) : this.script;
	}
*/

}
