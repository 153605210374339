import * as util from './utilities';


export function RenderException (value, message)
{
  this.value = value;
  this.message = message;
  this.toString = function() {
     return this.message;
  };
}


export default class BaseScriptGenerator
{
	constructor(splineValues, renderer)
	{
		this.splineValues = splineValues;
        this.renderer = renderer;
		this.COLOR = renderer.COLOR();
        this.script = [];
        
	} // constructor


	throwError(value, message)
    {
        throw new RenderException(value, message);   
    }

	
	createScript()
	{
		// Create an array with all of the teeth.
		var teeth = [];

		var incrementAngle = 360 / this.splineValues.numTeeth;
		for (var angle = 0, toothNum = 0; angle <= 360; angle += incrementAngle, toothNum++)
		{
			teeth[toothNum] = this.createTooth(angle);
		}

		// As we create smaller splines, the width of the strokes when we render them is too wide.
		// As such, we set the width proportial to the tooth spacing so that the stoke looks good
		// at all sizes of splines. 
		this.renderer.setStrokeWidth(this.splineValues.major / 6);

		// Render the major, minor, pitch and form circles
		this.renderHeader();
		this.renderCircles();
		this.renderTeeth(teeth);
		this.renderPins();
		this.renderText();
		this.renderFooter();
	
		return this.script;

	} // createScript


	// Renders the data of the spline in text format on the drawing.
	renderText()
	{
	}


	renderPins()
	{
		// For Internal splines, we are measring BETWEEN wires. For External, we measure 
		// OVER pins.
		var halfToothWidthAngle = 360 / this.splineValues.numTeeth / 2;
		var pinIncrementAngle = 0;

		// Note, we need the average pin measurement since we are using the average actual
		// tooth width or space.

		// Ok, so when we have an odd number of teeth, the pin measurements are not through 
		// the center of the spine gear. As such, we can't just use / 2 the measure as the
		// radius. We need something else.

		var pinCenterRadius = this.splineValues.pinCenterRadius;
		if (this.isInternal)
		{
//			pinCenterRadius = (this.splineValues.pinMeasurement + this.splineValues.pinDiameter) / 2;
		}
		else // EXTERNAL
		{
//			pinCenterRadius = (this.splineValues.pinMeasurement - this.splineValues.pinDiameter) / 2; // For EXTERNAL
			pinIncrementAngle = halfToothWidthAngle;
		}

		// Put the pins on a pins layer
		var newElements = this.renderer.layer("Pins", this.COLOR.GREEN);
		this.script = newElements ? this.script.concat(newElements) : this.script;

		// Draw first pin
		var pinAngle = util.toRadians(360 / this.splineValues.numTeeth + pinIncrementAngle);
		var x = pinCenterRadius * Math.sin(pinAngle);
		var y = pinCenterRadius * Math.cos(pinAngle);
		newElements = this.renderer.circle(x, y, this.splineValues.pinDiameter / 2, this.COLOR.GREEN);
		this.script = newElements ? this.script.concat(newElements) : this.script;

		// Draw opposite pin
		// If we have an odd number of teeth, then the pins are not 180 degrees apart
		// and we need to compensate by 1/2 a tooth width.
		var incrementAngle = 180;
		if (util.isOdd(this.splineValues.numTeeth))
		{
			incrementAngle += halfToothWidthAngle;
		}

		pinAngle = util.toRadians(360 / this.splineValues.numTeeth - incrementAngle + pinIncrementAngle);
		x = pinCenterRadius * Math.sin(pinAngle);
		y = pinCenterRadius * Math.cos(pinAngle);
		newElements = this.renderer.circle(x, y, this.splineValues.pinDiameter / 2, this.COLOR.GREEN);
		this.script = newElements ? this.script.concat(newElements) : this.script;
        
	} // renderPins
 

	renderTeeth(teeth)
	{
		// Render the teeth sides
		for (var toothNum = 0; toothNum < this.splineValues.numTeeth; toothNum++)
		{
			// Render the sides of each tooth
			var newElements = teeth[toothNum].draw(this.renderer, this.COLOR.BLACK);
			this.script = newElements ? this.script.concat(newElements) : this.script;
	
			// Draw the tip of each tooth.
			var start = teeth[toothNum].rightProfile.end;
            var end = teeth[toothNum].leftProfile.end;
            if (!util.isEquivalent(start, end))
            {
                newElements = this.renderer.arc(start.x, start.y, 0, 0, end.x, end.y, this.COLOR.BLACK);
                this.script = newElements ? this.script.concat(newElements) : this.script;    
            }
			
			// Draw the root of each tooth.
			var previousToothNum = toothNum - 1;
			if (previousToothNum < 0)
			{
				previousToothNum = this.splineValues.numTeeth - 1;
			}

			start = teeth[previousToothNum].leftProfile.start;
			end = teeth[toothNum].rightProfile.start;
            if (!util.isEquivalent(start, end))
            {
                newElements = this.renderer.arc(start.x, start.y, 0, 0, end.x, end.y, this.COLOR.BLACK);
                this.script = newElements ? this.script.concat(newElements) : this.script;
            }
		}

	} // renderTeeth


	renderCircles()
	{
		let newElements;

		// Create the red pitch layer and draw the pitch circle on it.
		if (this.splineValues.pitchDiameter)
		{
			newElements = this.renderer.layer("Pitch", this.COLOR.RED);
			this.script = newElements ? this.script.concat(newElements) : this.script;

			newElements = this.renderer.circle(0,0, this.splineValues.pitchDiameter / 2, this.COLOR.RED);
			this.script = newElements ? this.script.concat(newElements) : this.script;
		}
		
		// Create the yellow form layer and draw the form circle on it.
		if (this.splineValues.formDiameter)
		{
			newElements = this.renderer.layer("Form", this.COLOR.YELLOW);
			this.script = newElements ? this.script.concat(newElements) : this.script;

			newElements = this.renderer.circle(0,0, this.splineValues.formDiameter / 2, this.COLOR.YELLOW);
			this.script = newElements ? this.script.concat(newElements) : this.script;
		}

		// Create the grey broach layer
		newElements = this.renderer.layer("Broach", this.COLOR.BLACK);
		this.script = newElements ? this.script.concat(newElements) : this.script;

	} // renderCircles


	renderHeader()
	{
		var newElements = this.renderer.header();
		this.script = newElements ? this.script.concat(newElements) : this.script;

	} // renderHeader


	renderFooter()
	{
		var newElements = this.renderer.footer();
		this.script = newElements ? this.script.concat(newElements) : this.script;

	} // renderFooter

}
