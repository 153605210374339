import React from 'react';

function round(number, precision) {
  var shift = function (number, precision) {
    var numArray = ("" + number).split("e");
    return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
  };
  return shift(Math.round(shift(number, +precision)), -precision);
}

export default class InchMMCalculator extends React.Component
{  
  render()
  {
    return (
	  <div>
      <div>
        <label htmlFor="inches">Inches</label>
        <input type="number" id="inches" onInput={() => this.inchToMM()}></input>
      </div>
      <div>
        <label htmlFor="mm">mm</label>
        <input type="number" id="mm" onInput={() => this.mmtoInch()}></input>
      </div>
	  </div>
  	);
  }

  inchToMM()
  {
  	let inches = document.getElementById("inches").value;
    document.getElementById("mm").value = round(inches * 25.4, 4);
  }

  mmtoInch()
  {
  	let mm = document.getElementById("mm").value;
    document.getElementById("inches").value = round(mm / 25.4, 4);
  }

}